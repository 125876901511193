import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./social-media.scss";
import Footer from "../commons/footer";

import { ReactComponent as TwitterIcon } from "../../assets/images/icons/twitter-1.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/icons/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/instagram.svg";

const SocialMedia = () => {
  return (
    <>
      <div className="app-container">
        <div className="page-wrapper sc-page-wrapper">
          <div className="sc-section">
            <div className="sc-content">
              <a
                href="https://twitter.com/bdic_official"
                target="_blank"
                rel="noreferrer"
              >
                <div className="sc-icon">
                  <TwitterIcon width={40} height={40} />
                  <p>@bdic_official</p>
                </div>
              </a>
              <a href="#" target="_blank" rel="noreferrer">
                <div className="sc-icon">
                  <YoutubeIcon width={40} height={40} />
                  <p>@BDICTube</p>
                </div>
              </a>
              <a
                href="https://www.instagram.com/bdic_official/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="sc-icon">
                  <InstagramIcon width={40} height={40} />
                  <p>@bdic_official</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer className="sticky" />
    </>
  );
};

export default SocialMedia;
