import "./landing.scss";
import { animated } from "react-spring/renderprops";
import Header from "../commons/header";
import { Link } from "react-router-dom";
import { ReactComponent as LongRightArrow } from "../../assets/images/icons/long-right-arrow.svg";
import { Helmet } from "react-helmet";

export const Landing = ({ style }) => {
  return (
    <animated.div style={style}>
      <Helmet>
        <html lang="en" />
        <meta
          name="description"
          content="Dear not-so-little luxury brand, make a Wish..."
        />
      </Helmet>
      <Header className="sticky dark" hideMenu />
      <div className="landing">
        <div className="fullwidth-bg" />
        <div className="content">
          <h2>
            Dear not-so-little luxury brand, <br />
            make a Wish...
          </h2>
          <p>
            Only if wishes were horses. Else, tell us… what really is the nature
            of the problem you are trying to solve?
          </p>
          <Link to="/home">
            <div className="action-btn">
              <p className="mr-1">It all begins here.</p>
              <div className="long-arrow">
                <LongRightArrow />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </animated.div>
  );
};

export default Landing;
