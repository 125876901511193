import React, { useEffect } from "react";
import "../Live/live.scss";
import grid1 from "../../assets/images/grid-gallery/1.png";
import Footer from "../commons/footer";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const LiveDetails = () => {
  const [currentSlide] = React.useState(0);
  const [post, setPost] = React.useState([]);

  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    loadCW();
  }, []);

  const onLeftButtonClick = () => {
    history.push("/community-works");
  };

  const loadCW = async () => {
    try {
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/community-works/${id}`
      );
      setPost(response.data.data ?? []);
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
    }
  };

  const createDescriptionMarkup = () => {
    return { __html: post?.body };
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{post?.title ?? ""} - Community Works - BDIC</title>
        <meta
          name="description"
          content="Everybody can be great. Because anybody can serve. You don’t
    have to have a college degree to serve. You don’t have to make
    your subject and your verb agree to serve."
        />
      </Helmet>
      <div className="app-container">
        <div className="live-details-page-wrapper">
          <div>
            <div className="row">
              {post.featured_image_url && (
                <div className="col-lg-5">
                  <div
                    className="live-details-img"
                    style={{
                      backgroundImage: `url('${
                        post?.featured_image_url ?? ""
                      }')`,
                    }}
                  ></div>
                </div>
              )}
              <div className="col-lg-7">
                <div id="wrapper">
                  <div id="scroller">
                    <h2>{post?.title}</h2>
                    <p
                      className="live-detatils-text"
                      dangerouslySetInnerHTML={createDescriptionMarkup()}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        showLeftArrow
        onLeftButtonClick={onLeftButtonClick}
      />
    </>
  );
};

export default LiveDetails;
