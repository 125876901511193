import React from "react";
import { Modal, Carousel } from "react-bootstrap";

export const AvailableWorks = ({ gallery, category = "originals" }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);

  const handleShowFullImage = (data) => () => {
    setShowModal(!showModal);
    setModalData(data);
  };

  const getGallery = () => {
    const galleryData = gallery.filter(
      (g) =>
        g.category?.toLowerCase() === category?.toLowerCase() &&
        g?.visibility?.toLowerCase() === "private"
    );
    if (galleryData.length) {
      return (
        <>
          <Carousel>
            {galleryData.map((g) => (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={`https://admin.bdicofficial.com/${g?.image_url ?? ""}`}
                  alt={g?.title ?? ""}
                  style={{
                    height: 300,
                    objectFit: "cover",
                    cursor: "zoom-in",
                  }}
                  onClick={handleShowFullImage(g)}
                />
                <Carousel.Caption>
                  <p style={{ textAlign: "center", marginTop: 10 }}>
                    {g?.title ?? ""} | {g?.status ?? ""}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          <Modal
            show={showModal}
            onHide={handleShowFullImage(null)}
            backdrop="static"
            contentClassName="client-modal-img"
          >
            <Modal.Header
              closeButton
              className="client-details-modal-img-header"
            ></Modal.Header>
            <Modal.Body className="client-details-modal-img-body">
              <div
                className="client-details-img client-details-modal-img"
                style={{
                  backgroundImage: `url('https://admin.bdicofficial.com/${
                    modalData?.image_url ?? ""
                  }')`,
                  cursor: "zoom-out",
                }}
                onClick={handleShowFullImage(null)}
              ></div>
              <div className="client-details-modal-img-description">
                <p>
                  {modalData?.title ?? ""} | {modalData?.medium ?? ""} |{" "}
                  {modalData?.year_completed ?? ""} | {modalData?.status ?? ""}
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 30,
            background: "#d3d3d370",
            height: 300,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>Not available at the moment.</p>
        </div>
      );
    }
  };

  return gallery ? getGallery() : null;
};
