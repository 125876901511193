import React, { useState } from "react";
import { Col, Modal } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { validateEmail } from "../../../utils/validateEmail";
import { Link, useHistory, useParams } from "react-router-dom";

const INITIAL_FORM_DATA = {
  fullname: "",
  email: "",
  phone_number: "",
  preferred_platform: "",
  current_location: "",
};

export const ScheduleCall = ({ post }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onChangeText = (name) => (evt) => {
    setFormData({
      ...formData,
      [name]: evt.target.value,
    });
    if (isEmpty(evt.target.value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: `This field is required and cannot be empty.`,
      }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const requiredFieldsAreNotEmpty = (requiredInputs) => {
    let errorCount = 0;
    requiredInputs.forEach((input) => {
      if (input === "email") {
        if (!validateEmail(formData[input])) {
          setFormErrors((prev) => ({
            ...prev,
            [input]: `Email is not valid`,
          }));
          errorCount++;
        }
      }
      if (isEmpty(formData[input])) {
        setFormErrors((prev) => ({
          ...prev,
          [input]: `This field is required and cannot be empty.`,
        }));
        errorCount++;
      }
    });

    if (errorCount > 0) {
      return false;
    }

    errorCount = 0;
    setFormErrors({});
    return true;
  };

  const handleFormSubmitPerState = () => {
    const resetForm = () => {
      setFormData(INITIAL_FORM_DATA);
    };

    let requiredFields = [
      "fullname",
      "email",
      "phone_number",
      "preferred_platform",
      "current_location",
    ];

    if (requiredFieldsAreNotEmpty(requiredFields)) {
      setSubmitting(true);

      try {
        setSubmitting(true);
        // axios.post("/api/sendmail", formData);
        axios
          .post("/.netlify/functions/schedule-call", {
            ...formData,
            clientName: post?.name,
            clientEmail: post?.email,
          })
          .then((res) => {
            setSubmitting(false);
            if (res.data.result !== "success") {
              alert("Email failed to send. Please try again.");
              setTimeout(() => {
                resetForm();
              }, 6000);
            } else {
              alert(
                `Thank you for requesting a virtual consultation with ${post.name}. A representative will contact via email with updates.`
              );
              resetForm();
            }
          })
          .catch((err) => {
            // PS: we are on holiday now till January 11, 2021.
            // from now till January
            setSubmitting(false);
          });
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  return (
    <Col lg={6} sm={12} className="schedule-call-container">
      <p className="input-title dark">Fullname:</p>
      <div className="input-container dark">
        <input
          className="input dark outline"
          onChange={onChangeText("fullname")}
          value={formData.fullname}
        />
        {formErrors["fullname"] && (
          <p className="form-error-text">{formErrors["fullname"]}</p>
        )}
      </div>
      <p className="input-title dark">Email:</p>
      <div className="input-container dark">
        <input
          className="input dark outline"
          onChange={onChangeText("email")}
          value={formData.email}
          type="email"
        />
        {formErrors["email"] && (
          <p className="form-error-text">{formErrors["email"]}</p>
        )}
      </div>
      <p className="input-title dark">Phone number:</p>
      <div className="input-container dark">
        <input
          className="input dark outline"
          onChange={onChangeText("phone_number")}
          value={formData.phone_number}
        />
        {formErrors["phone_number"] && (
          <p className="form-error-text">{formErrors["phone_number"]}</p>
        )}
      </div>
      <p className="input-title dark">
        Preferred Virtual consultation platform:
      </p>
      <div className="input-container dark">
        <select
          className="input dark outline"
          onChange={onChangeText("preferred_platform")}
          value={formData.preferred_platform}
          name="preferred_platform"
        >
          <option></option>
          <option>Zoom</option>
          <option>Google Meet</option>
        </select>
        {formErrors["preferred_platform"] && (
          <p className="form-error-text">{formErrors["preferred_platform"]}</p>
        )}
      </div>
      <p className="input-title dark">Current Location:</p>
      <div className="input-container dark mb-3">
        <input
          className="input dark outline"
          onChange={onChangeText("current_location")}
          value={formData.current_location}
        />
        {formErrors["current_location"] && (
          <p className="form-error-text">{formErrors["current_location"]}</p>
        )}
      </div>
      {/* <p className="input-title dark">Date:</p>
      <div className="input-container dark mb-3">
        <input
          className="input dark outline"
          onChange={onChangeText("date")}
          value={formData.date}
          type="datetime-local"
        />
        {formErrors["date"] && (
          <p className="form-error-text">{formErrors["date"]}</p>
        )}
      </div> */}
      <div className="continue-btn dark" onClick={handleFormSubmitPerState}>
        <Link>
          {submitting ? "Submitting..." : "Submit"} &nbsp;
          <svg
            width="46"
            height="15"
            viewBox="0 0 46 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
              fill="white"
            />
            <path d="M45.425 7.0708H0V7.88824H45.425V7.0708Z" fill="white" />
          </svg>
        </Link>
      </div>
    </Col>
  );
};
