import React, { useCallback, useEffect, useRef, useState } from "react";
import "./training.scss";
import { Link, useHistory } from "react-router-dom";
import { useWindowSize } from "../../utils/useWindowResize";
import { sortEveryNth } from "../../utils/sortEveryNth";
import { ReactComponent as LongRightArrow } from "../../assets/images/icons/long-right-arrow.svg";

// import Gallery from "react-photo-gallery";
import Gallery from "react-grid-gallery";
import Footer from "../commons/footer";
import SelectedImage from "./selectedImage";
import grid1 from "../../assets/images/grid-gallery/1.png";
import grid2 from "../../assets/images/grid-gallery/2.png";
import grid3 from "../../assets/images/grid-gallery/3.png";
import grid4 from "../../assets/images/grid-gallery/4.png";

const photos = [
  {
    src: grid1,
    thumbnail:
      "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    isSelected: true,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: grid2,
    thumbnail:
      "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    tags: [
      { value: "Ocean", title: "Ocean" },
      { value: "People", title: "People" },
    ],
    caption: "Boats (Jeshu John - designerspics.com)",
  },

  {
    src: grid4,
    thumbnail:
      "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: grid3,
    thumbnail:
      "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: grid3,
    thumbnail:
      "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
];

const GalleryWrapper = (props) => {
  return (
    <div {...props} className="gallery-wrapper">
      {props.children}
    </div>
  );
};

const GalleryImage = (props) => {
  return (
    <div
      className={`gallery-image-wrapper gallery-image-wrapper-${
        props.index + 1
      }`}
    >
      <div
        className="gallery-image"
        style={{
          background: `url(${props.src})`,
        }}
      >
        <p>Information about this post goes here,</p>
        <Link to={`/training/${props.index}`}>
          <p className="very-bold">
            Read more &nbsp;
            <LongRightArrow />
          </p>
        </Link>
      </div>
    </div>
  );
};

const Training = () => {
  const [width] = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState(photos);
  const [sortedImageData, setSortedImageData] = useState([]);

  useEffect(() => {
    if (width > 1366) {
      setSortedImageData(sortEveryNth(imageData, 5));
    } else if (width > 1024 && width <= 1366) {
      setSortedImageData(sortEveryNth(imageData, 3));
    } else if (width > 800 && width <= 1024) {
      setSortedImageData(sortEveryNth(imageData, 2));
    } else {
      setSortedImageData(imageData);
    }
  }, [width, imageData]);
  return (
    <>
      <div className="app-container">
        <div className="page-wrapper">
          <div className="training-section">
            <div className="training-header-section">
              <h1 className="training-header-tile">Training</h1>
              <p className="training-header-description">
                Come back here often, and follow our social media pages to find
                out first when we put up activities, workshops and openings for
                just a limited capacity here.
              </p>
            </div>
            <GalleryWrapper>
              {sortedImageData.map((data, index) => (
                <GalleryImage key={index} src={data.src} index={index} />
              ))}
            </GalleryWrapper>
          </div>
        </div>
      </div>
      <Footer className="sticky" />
    </>
  );
};

export default Training;
