import React, { useEffect, useState } from "react";
import "../Live/live.scss";
import "./clients.scss";
import Footer from "../commons/footer";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { Modal, Carousel, Row, Col } from "react-bootstrap";
import { validateEmail } from "../../utils/validateEmail";

const INITIAL_FORM_DATA = {
  firstname: "",
  surname: "",
  email: "",
  location: "",
  designation: "",
  who_are_you: "",
  other_who_are_you: "",
  purpose_of_inquiry: "",
  other_purpose_of_inquiry: "",
  more_about_inquiry: "",
  other_purpose_of_inquiry_exhibitionCollaborations: "",
  other_purpose_of_inquiry_date: "",
  other_purpose_of_inquiry_ECLocation: "",
  other_purpose_of_inquiry_ECVenue: "",
};

const ClientDetailsMore = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formErrors, setFormErrors] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [post, setPost] = React.useState([]);
  const [completeData, setCompleteData] = React.useState();
  const [submitting, setSubmitting] = useState(false);
  const [currentForm, setCurrentForm] = useState(0);

  const history = useHistory();
  let { id, uuid } = useParams();

  useEffect(() => {
    loadFullClientInfo();
  }, []);

  const onLeftArrowClick = () => {
    history.push(`/clients/${id}`);
  };

  const onRightArrowClick = () => {
    // history.push(`/clients/${id}`);
  };

  const loadFullClientInfo = async () => {
    try {
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/clients/${id}/${uuid}`
        // `http://127.0.0.1:8000/api/clients/${id}/${uuid}`
      );
      console.log("response.data", response.data);
      setPost(response.data);
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
    }
  };

  const handleShowFullImage = (data) => () => {
    setShowModal(!showModal);
    setModalData(data);
  };

  const onChangeText = (name) => (evt) => {
    setFormData({
      ...formData,
      [name]: evt.target.value,
    });
    if (isEmpty(evt.target.value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: `This field is required and cannot be empty.`,
      }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const requiredFieldsAreNotEmpty = (requiredInputs) => {
    let errorCount = 0;
    requiredInputs.forEach((input) => {
      if (input === "email") {
        if (!validateEmail(formData[input])) {
          setFormErrors((prev) => ({
            ...prev,
            [input]: `Email is not valid`,
          }));
          errorCount++;
        }
      }
      if (isEmpty(formData[input])) {
        setFormErrors((prev) => ({
          ...prev,
          [input]: "This field is required and cannot be empty.",
        }));
        errorCount++;
      }
    });

    if (errorCount > 0) {
      return false;
    }

    errorCount = 0;
    setFormErrors({});
    return true;
  };

  const handleFormSubmitPerState = (form) => () => {
    switch (form) {
      case -1:
        setCurrentForm(0);
        break;
      case 0:
        if (
          requiredFieldsAreNotEmpty([
            "surname",
            "firstname",
            "email",
            "location",
            "designation",
          ])
        ) {
          setCurrentForm(1);
        }
        break;
      case 1:
        const resetForm = () => {
          setFormData(INITIAL_FORM_DATA);
          setCurrentForm(0);
        };

        let requiredFields = [
          "who_are_you",
          "purpose_of_inquiry",
          "more_about_inquiry",
        ];

        if (formData.who_are_you === "Other") {
          requiredFields.push("other_who_are_you");
        }

        if (
          formData.purpose_of_inquiry ===
          "Booking (e.g., events, masterclasses, et cetera)"
        ) {
          requiredFields.push("other_purpose_of_inquiry_date");
        }
        if (formData.purpose_of_inquiry === "Licensing") {
          requiredFields.push("other_purpose_of_inquiry_purpose");
          requiredFields.push("other_purpose_of_inquiry_application");
        }
        if (formData.purpose_of_inquiry === "Exhibitions Collaborations") {
          requiredFields.push(
            "other_purpose_of_inquiry_exhibitionCollaborations"
          );
        }
        // if (formData.purpose_of_inquiry === "Exhibitions Collaborations") {
        //   requiredFields.push(
        //     "other_purpose_of_inquiry_exhibitionCollaborations"
        //   );
        // }
        if (
          formData.other_purpose_of_inquiry_exhibitionCollaborations &&
          formData.other_purpose_of_inquiry_exhibitionCollaborations === "Local"
        ) {
          requiredFields.push("other_purpose_of_inquiry_ECLocation");
          requiredFields.push("other_purpose_of_inquiry_ECVenue");
        }

        if (
          possibleValuesWithOtherFields.includes(formData.purpose_of_inquiry)
        ) {
          requiredFields.push("other_purpose_of_inquiry");
        }

        if (requiredFieldsAreNotEmpty(requiredFields)) {
          setSubmitting(true);

          try {
            setSubmitting(true);
            // axios.post("/api/sendmail", formData);
            axios
              .post("/.netlify/functions/request-more-about-client", {
                ...formData,
                client: uuid,
                clientName: post?.name,
                clientEmail: post?.email,
              })
              .then((res) => {
                setSubmitting(false);
                console.log("submit response: ", res);
                if (res.data.result !== "success") {
                  alert("Email failed to send. Please try again.");
                } else {
                  alert(
                    `Thank you for your inquiry about ${post?.name}. We usually respond in 2 - 3 business days and offer a unique access link to learn more.`
                  );
                  resetForm();
                }
              })
              .catch((err) => {
                // PS: we are on holiday now till January 11, 2021.
                // from now till January
                setSubmitting(false);
              });
          } catch (error) {
            setSubmitting(false);
          }
        }
        return;
      default:
        return;
    }
  };

  // const handleFormSubmitPerState = (form) => () => {};

  const createDescriptionMarkup = () => {
    return { __html: post?.description };
  };

  const possibleValuesWithOtherFields = [
    "Invitation (e.g., exhibitions, conferences et cetera)",
    "Commissions",
    "Rental",
    "Other",
  ];

  const placeholderName = () => {
    const placeholders = ["Purpose", "Type", "Purpose", ""];
    const possibleValues = possibleValuesWithOtherFields;

    const valueIndex = possibleValues.findIndex(
      (val) =>
        val?.toLowerCase() === formData?.purpose_of_inquiry?.toLowerCase()
    );
    console.log("valueIndex", valueIndex);

    if (valueIndex === -1) {
      return "";
    }

    return placeholders[valueIndex];
  };

  return (
    <>
      <div className="app-container">
        <div className="client-details-page-wrapper">
          <Row>
            {!isEmpty(post?.gallery) && (
              <Col lg={7} className="mb-3">
                {/* {post?.name && <h3>Some of {post?.name ?? ""}'s works</h3>} */}

                <Carousel>
                  {post?.gallery
                    .filter(
                      (gal) =>
                        gal?.visibility?.toLowerCase() === "public" &&
                        gal?.category?.toLowerCase() !== "portrait commissions"
                    )
                    .slice(0, 3)
                    .map((g) => (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={`https://admin.bdicofficial.com/${
                            g?.image_url ?? ""
                          }`}
                          alt={g?.title ?? ""}
                          style={{
                            height: 350,
                            objectFit: "cover",
                            cursor: "zoom-in",
                          }}
                          onClick={handleShowFullImage(g)}
                        />
                        <Carousel.Caption>
                          <p style={{ textAlign: "center", marginTop: 10 }}>
                            {g?.title ?? ""} | {g?.status ?? ""}
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </Col>
            )}
            <Col lg={1}></Col>
            <Col lg={4}>
              <h6>For more inquiry and to view current works:</h6>
              {/* <p className="very-bold">Fill Form:</p> */}
              {currentForm === 0 && (
                <>
                  <p className="input-title dark">SURNAME:</p>
                  <div className="input-container dark">
                    <input
                      className="input dark outline client-input"
                      onChange={onChangeText("surname")}
                      value={formData.surname}
                      autoFocus
                    />
                    {formErrors["surname"] && (
                      <p className="form-error-text">{formErrors["surname"]}</p>
                    )}
                  </div>
                  <p className="input-title dark">first name:</p>
                  <div className="input-container dark">
                    <input
                      className="input dark outline client-input"
                      onChange={onChangeText("firstname")}
                      value={formData.firstname}
                    />
                    {formErrors["firstname"] && (
                      <p className="form-error-text">
                        {formErrors["firstname"]}
                      </p>
                    )}
                  </div>
                  <p className="input-title dark">email:</p>
                  <div className="input-container dark">
                    <input
                      className="input dark outline client-input"
                      onChange={onChangeText("email")}
                      value={formData.email}
                    />
                    {formErrors["email"] && (
                      <p className="form-error-text">{formErrors["email"]}</p>
                    )}
                  </div>
                  <p className="input-title dark">location:</p>
                  <div className="input-container dark">
                    <input
                      className="input dark outline client-input"
                      onChange={onChangeText("location")}
                      value={formData.location}
                    />
                    {formErrors["location"] && (
                      <p className="form-error-text">
                        {formErrors["location"]}
                      </p>
                    )}
                  </div>
                  <p className="input-title dark">Designation:</p>
                  <div className="input-container dark mb-2">
                    <input
                      className="input dark outline client-input"
                      onChange={onChangeText("designation")}
                      value={formData.designation}
                    />
                    {formErrors["designation"] && (
                      <p className="form-error-text">
                        {formErrors["designation"]}
                      </p>
                    )}
                  </div>

                  <div
                    className="continue-btn dark"
                    onClick={handleFormSubmitPerState(0)}
                  >
                    <Link>
                      Continue &nbsp;
                      <svg
                        width="46"
                        height="15"
                        viewBox="0 0 46 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                          fill="white"
                        />
                        <path
                          d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </div>
                </>
              )}

              {currentForm === 1 && (
                <>
                  <p className="input-title dark">Who are you?:</p>
                  <div className="input-container">
                    <select
                      className="input dark outline client-input"
                      onChange={onChangeText("who_are_you")}
                      value={formData.who_are_you}
                      name="who_are_you"
                    >
                      <option></option>
                      <option>Art Collector</option>
                      <option>Auctioneer</option>
                      <option>Curator</option>
                      <option>Interior Designer</option>
                      <option>Gallery Owner/Representative</option>
                      <option>Potential Client</option>
                      <option>Artist</option>
                      <option>Entertainer</option>
                      <option>Agent</option>
                      <option>Agency</option>
                      <option>Film/Set/Music Director</option>
                      <option>Art Lover</option>
                      <option>Event Coordinator</option>
                      <option>Other</option>
                    </select>
                    {formErrors["who_are_you"] && (
                      <p className="form-error-text">
                        {formErrors["who_are_you"]}
                      </p>
                    )}
                  </div>
                  {formData.who_are_you === "Other" && (
                    <>
                      {/* <p className="input-title"></p> */}
                      <div className="input-container">
                        <input
                          className="input dark outline client-input"
                          onChange={onChangeText("other_who_are_you")}
                          value={formData.other_who_are_you}
                          name="other_who_are_you"
                        />
                        {formErrors["other_who_are_you"] && (
                          <p className="form-error-text">
                            {formErrors["other_who_are_you"]}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  <p className="input-title dark">Purpose of inquiry:</p>
                  <div className="input-container">
                    <select
                      className="input dark outline client-input"
                      onChange={onChangeText("purpose_of_inquiry")}
                      value={formData.purpose_of_inquiry}
                      name="purpose_of_inquiry"
                    >
                      <option></option>
                      <option>Buy Artwork</option>
                      <option>
                        Booking (e.g., events, masterclasses, et cetera)
                      </option>
                      <option>
                        Invitation (e.g., exhibitions, conferences et cetera)
                      </option>
                      <option>Commissions</option>
                      <option>Licensing</option>
                      <option>Rental</option>
                      <option>Exhibitions Collaborations</option>
                      <option>Other</option>
                    </select>
                    {formErrors["purpose_of_inquiry"] && (
                      <p className="form-error-text">
                        {formErrors["purpose_of_inquiry"]}
                      </p>
                    )}
                  </div>
                  {formData.purpose_of_inquiry ===
                    "Booking (e.g., events, masterclasses, et cetera)" && (
                    <>
                      <p className="input-title dark">
                        {placeholderName() ?? ""}
                      </p>
                      <div className="input-container">
                        <input
                          className="input dark outline client-input"
                          onChange={onChangeText(
                            "other_purpose_of_inquiry_date"
                          )}
                          value={formData.other_purpose_of_inquiry_date}
                          name="other_purpose_of_inquiry_date"
                          placeholder={placeholderName()}
                          type="datetime-local"
                        />
                        {formErrors["other_purpose_of_inquiry_date"] && (
                          <p className="form-error-text">
                            {formErrors["other_purpose_of_inquiry_date"]}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {formData.purpose_of_inquiry === "Licensing" && (
                    <Row>
                      <Col lg={6} style={{ padding: 0, paddingRight: 5 }}>
                        <p className="input-title dark">Date</p>
                        <div className="input-container">
                          <input
                            className="input dark outline client-input"
                            onChange={onChangeText(
                              "other_purpose_of_inquiry_purpose"
                            )}
                            value={formData.other_purpose_of_inquiry_purpose}
                            name="other_purpose_of_inquiry_purpose"
                            placeholder={placeholderName()}
                            type="datetime-local"
                          />
                          {formErrors["other_purpose_of_inquiry_purpose"] && (
                            <p className="form-error-text">
                              {formErrors["other_purpose_of_inquiry_purpose"]}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} style={{ padding: 0, paddingLeft: 5 }}>
                        <p className="input-title dark">Application</p>
                        <div className="input-container">
                          <input
                            className="input dark outline client-input"
                            style={{ width: "100%" }}
                            onChange={onChangeText(
                              "other_purpose_of_inquiry_application"
                            )}
                            value={
                              formData.other_purpose_of_inquiry_application
                            }
                            name="other_purpose_of_inquiry_application"
                            placeholder="Application"
                          />
                          {formErrors[
                            "other_purpose_of_inquiry_application"
                          ] && (
                            <p className="form-error-text">
                              {
                                formErrors[
                                  "other_purpose_of_inquiry_application"
                                ]
                              }
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {formData.purpose_of_inquiry ===
                    "Exhibitions Collaborations" && (
                    <Row>
                      <Col lg={12} style={{ padding: 0 }}>
                        <p className="input-title dark">Select Type</p>
                        <div className="input-container">
                          <select
                            className="input dark outline client-input"
                            onChange={onChangeText(
                              "other_purpose_of_inquiry_exhibitionCollaborations"
                            )}
                            value={
                              formData.other_purpose_of_inquiry_exhibitionCollaborations
                            }
                            name="other_purpose_of_inquiry_exhibitionCollaborations"
                          >
                            <option></option>
                            <option>International</option>
                            <option>Local</option>
                          </select>
                          {formErrors[
                            "other_purpose_of_inquiry_exhibitionCollaborations"
                          ] && (
                            <p className="form-error-text">
                              {
                                formErrors[
                                  "other_purpose_of_inquiry_exhibitionCollaborations"
                                ]
                              }
                            </p>
                          )}
                        </div>
                      </Col>
                      {formData.other_purpose_of_inquiry_exhibitionCollaborations &&
                        formData.other_purpose_of_inquiry_exhibitionCollaborations ===
                          "Local" && (
                          <>
                            <Col lg={6} style={{ padding: 0, paddingRight: 5 }}>
                              <p className="input-title dark">Location</p>
                              <div className="input-container">
                                <input
                                  className="input dark outline client-input"
                                  style={{ width: "100%" }}
                                  onChange={onChangeText(
                                    "other_purpose_of_inquiry_ECLocation"
                                  )}
                                  value={
                                    formData.other_purpose_of_inquiry_ECLocation
                                  }
                                  name="other_purpose_of_inquiry_ECLocation"
                                  placeholder="Location"
                                />
                                {formErrors[
                                  "other_purpose_of_inquiry_ECLocation"
                                ] && (
                                  <p className="form-error-text">
                                    {
                                      formErrors[
                                        "other_purpose_of_inquiry_ECLocation"
                                      ]
                                    }
                                  </p>
                                )}
                              </div>
                            </Col>
                            <Col lg={6} style={{ padding: 0, paddingLeft: 5 }}>
                              <p className="input-title dark">Venue</p>
                              <div className="input-container">
                                <input
                                  className="input dark outline client-input"
                                  style={{ width: "100%" }}
                                  onChange={onChangeText(
                                    "other_purpose_of_inquiry_ECVenue"
                                  )}
                                  value={
                                    formData.other_purpose_of_inquiry_ECVenue
                                  }
                                  name="other_purpose_of_inquiry_ECVenue"
                                  placeholder="Venue"
                                />
                                {formErrors[
                                  "other_purpose_of_inquiry_ECVenue"
                                ] && (
                                  <p className="form-error-text">
                                    {
                                      formErrors[
                                        "other_purpose_of_inquiry_ECVenue"
                                      ]
                                    }
                                  </p>
                                )}
                              </div>
                            </Col>
                          </>
                        )}
                    </Row>
                  )}
                  {possibleValuesWithOtherFields.includes(
                    formData.purpose_of_inquiry
                  ) && (
                    <>
                      <p className="input-title dark">
                        {placeholderName() ?? ""}
                      </p>
                      <div className="input-container">
                        <input
                          className="input dark outline client-input"
                          onChange={onChangeText("other_purpose_of_inquiry")}
                          value={formData.other_purpose_of_inquiry}
                          name="other_purpose_of_inquiry"
                          placeholder={placeholderName()}
                        />
                        {formErrors["other_purpose_of_inquiry"] && (
                          <p className="form-error-text">
                            {formErrors["other_purpose_of_inquiry"]}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  <p className="input-title dark">
                    Tell us a bit more about your purpose of inquiry:
                  </p>
                  <div className="input-container">
                    <textarea
                      className="input dark outline client-input"
                      rows="3"
                      cols="60"
                      onChange={onChangeText("more_about_inquiry")}
                      value={formData.more_about_inquiry}
                      name="more_about_inquiry"
                    ></textarea>
                    {formErrors["more_about_inquiry"] && (
                      <p className="form-error-text">
                        {formErrors["more_about_inquiry"]}
                      </p>
                    )}
                  </div>
                  <Row>
                    <Col lg={4}>
                      <div
                        className="continue-btn dark"
                        onClick={handleFormSubmitPerState(-1)}
                      >
                        <Link>
                          <svg
                            width="31"
                            height="10"
                            viewBox="0 0 88 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M14.3786 29L15.4785 27.8147L2.19997 14.4605L15.4785 1.10627L14.3786 0L-3.05176e-05 14.4605L14.3786 29Z"
                                fill="white"
                              />
                              <path
                                d="M1.10001 13.6703H88V15.2507H1.10001V13.6703Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect
                                  width="88"
                                  height="29"
                                  fill="white"
                                  transform="matrix(-1 0 0 1 88 0)"
                                />
                              </clipPath>
                            </defs>
                          </svg>{" "}
                          &nbsp; Back
                        </Link>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div
                        className="continue-btn dark"
                        onClick={handleFormSubmitPerState(1)}
                      >
                        <Link>
                          {submitting ? "Submitting..." : "Submit"} &nbsp;
                          <svg
                            width="46"
                            height="15"
                            viewBox="0 0 46 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                              fill="white"
                            />
                            <path
                              d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                              fill="white"
                            />
                          </svg>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleShowFullImage(null)}
        backdrop="static"
        contentClassName="client-modal-img"
      >
        <Modal.Header
          closeButton
          className="client-details-modal-img-header"
        ></Modal.Header>
        <Modal.Body className="client-details-modal-img-body">
          <div
            className="client-details-img client-details-modal-img"
            style={{
              backgroundImage: `url('https://admin.bdicofficial.com/${
                modalData?.image_url ?? ""
              }')`,
              cursor: "zoom-out",
            }}
            onClick={handleShowFullImage(null)}
          ></div>
          <div className="client-details-modal-img-description">
            <p style={{ textTransform: "capitalize" }}>
              {modalData?.title ?? ""} | {modalData?.medium ?? ""} |{" "}
              {modalData?.year_completed ?? ""} | {modalData?.status ?? ""}
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Footer
        className="sticky"
        // showRightArrow
        showLeftArrow
        onLeftButtonClick={onLeftArrowClick}
        onRightArrowClick={onRightArrowClick}
      />
    </>
  );
};

export default ClientDetailsMore;
