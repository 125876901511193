import React, { useEffect, useState } from "react";
import "../Live/live.scss";
import "./clients.scss";
import grid1 from "../../assets/images/grid-gallery/1.png";
import Footer from "../commons/footer";
import isEmpty from "lodash/isEmpty";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Col, Modal, Row } from "react-bootstrap";

import { AvailableWorks } from "./_partials/available-works";
import { ScheduleCall } from "./_partials/schedule-call";
import { mockComponent } from "react-dom/test-utils";
import { UpcomingEvents } from "./_partials/upcoming-events";
import { PastExhibitions } from "./_partials/past-exhibitions";

const ClientFullInfo = () => {
  const [currentSlide] = React.useState(0);
  const [activeMenu, setActiveMenu] = useState("available_works");
  const [post, setPost] = React.useState([]);

  const history = useHistory();
  let { id, uuid } = useParams();

  useEffect(() => {
    loadFullClientInfo();
  }, []);

  const onRightArrowClick = () => {
    post.uuid && history.push(`/clients/${id}`);
  };

  const loadClientInfo = async () => {
    try {
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/clients/${id}`
      );
      setPost(response.data ?? {});
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
    }
  };

  const loadFullClientInfo = async () => {
    try {
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/clients/${id}/${uuid}`
      );
      console.log("response.data", response.data);
      setPost(response.data);
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
    }
  };

  const createDescriptionMarkup = () => {
    return { __html: post?.description };
  };

  const handleMenuClick = (key) => () => {
    setActiveMenu(key);
  };

  const renderMenuContent = () => {
    switch (activeMenu) {
      case "available_works":
        return <AvailableWorks gallery={post.gallery} />;
      case "portrait":
        return (
          <AvailableWorks
            gallery={post.gallery}
            category="Portrait Commissions"
          />
        );
      case "schedule_call":
        return <ScheduleCall post={post} />;
      case "upcoming_event":
        return <UpcomingEvents post={post?.events} />;
      case "past_exhibitions":
        return <PastExhibitions post={post?.past_exhibitions} />;
      default:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 30,
              background: "#d3d3d370",
              height: 300,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>Not available at the moment.</p>
          </div>
        );
    }
  };

  const isLinkExpired = () => {
    const dateFrom = new Date();
    const dateTo = new Date(post.expiry_date);
    return dateFrom > dateTo;
  };

  return (
    <>
      <div className="app-container client-app-container">
        <div className="live-details-page-wrapper">
          {!isEmpty(post) && !isLinkExpired() && (
            <div className="w-100" style={{ flex: 1 }}>
              <Row>
                <Col lg={4}>
                  <ul className="client-side-menu">
                    <li>
                      <Link onClick={handleMenuClick("available_works")}>
                        <h6
                          className={`side-menu-link ${
                            activeMenu === "available_works" && "active"
                          }`}
                        >
                          See Select Available Works
                        </h6>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleMenuClick("portrait")}>
                        <h6
                          className={`side-menu-link ${
                            activeMenu === "portrait" && "active"
                          }`}
                        >
                          Portrait Commissions
                        </h6>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleMenuClick("schedule_call")}>
                        <h6
                          className={`side-menu-link ${
                            activeMenu === "schedule_call" && "active"
                          }`}
                        >
                          Request A Virtual Consultation (GoogleMeet, Zoom)
                        </h6>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleMenuClick("upcoming_event")}>
                        <h6
                          className={`side-menu-link ${
                            activeMenu === "upcoming_event" && "active"
                          }`}
                        >
                          See Upcoming Events
                        </h6>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleMenuClick("past_exhibitions")}>
                        <h6
                          className={`side-menu-link ${
                            activeMenu === "past_exhibitions" && "active"
                          }`}
                        >
                          Past Exhibitions
                        </h6>
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col lg={8} className="full-info-content-container">
                  <div className="full-info-content">{renderMenuContent()}</div>

                  {/* <div id="wrapper">
                  <div id="scroller">
                    <h2>{post?.name}</h2>
                    <p
                      className="live-detatils-text"
                      dangerouslySetInnerHTML={createDescriptionMarkup()}
                    ></p>
                  </div>
                </div> */}
                </Col>
              </Row>
            </div>
          )}
          {isEmpty(post) && isLinkExpired() ? (
            <div
              style={{
                width: "100%",
                height: 300,
                padding: 30,
                background: "#d3d3d370",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5>This link has expired, sorry 😕</h5>
            </div>
          ) : isLinkExpired() ? (
            <div
              style={{
                width: "100%",
                height: 300,
                padding: 30,
                background: "#d3d3d370",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5>This link has expired, sorry 😕</h5>
            </div>
          ) : null}
        </div>
      </div>

      <Footer
        className="sticky"
        // showRightArrow
        // onRightButtonClick={onRightArrowClick}
      />
    </>
  );
};

export default ClientFullInfo;
