import React from "react";
import { animated } from "react-spring/renderprops";
import "./appdark.css";
import "../../assets/scss/styles.scss";
import Header from "../commons/header";

function AppDark(props) {
  return (
    <animated.div style={props.style}>
      <div className="fullwidth-dark-bg">
        <Header className="sticky dark" />
        {props.children}
      </div>
    </animated.div>
  );
}

export default AppDark;
