import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../commons/footer";
import "./about.scss";
import { Helmet } from "react-helmet";

const About = ({ history, style }) => {
  const onRightButtonClick = () => {
    history.push("/our-promise");
  };
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>ABOUT US - BDIC</title>
        <meta
          name="description"
          content="People call us WOW Merchants in the Middle East, South of Asia
          and Western Africa, but our favourite clients welcome from
          every corners of the world, can call us their friends. "
        />
      </Helmet>
      <div className="app-container">
        <div className="page-wrapper">
          <Container fluid>
            <Row>
              <Col md={12} lg={12} xl={1} className="pt-0">
                <h3 className="about-page-title">About</h3>
              </Col>
              <Col md={12} lg={3}>
                <h1 className="about-headline-text">
                  Who are we? <br />
                  /‘bee-dee-‘k/
                </h1>
              </Col>
              <Col md={12} lg={4}>
                <p className="about-paragraph">
                  People call us WOW Merchants in the Middle East, South of Asia
                  and Western Africa, but our favourite clients welcome from
                  every corners of the world, can call us their friends. <br />
                  <br />
                  We specialise in positioning emerging luxury brands in
                  fashion, accessories, art, fragrance, hospitality and
                  recreation profitably in highly competitive markets; and even
                  better, we know how to find water in deserts. <br />
                  <br />
                  We hate red oceans. <br />
                  We don’t create sensations. <br />
                  We hate flimflam. <br />
                  We build. You dominate. And we all enjoy in clover… our way of
                  saying, in ease and luxury. <br />
                  <br />
                  We have documented successes over the years working with
                  advanced start-ups,
                </p>
              </Col>
              <Col md={12} lg={4} pull-left>
                <p className="about-paragraph">
                  select celebrities et cetera, and our results have not only
                  been used as case studies in institution but also plastered
                  endlessly across international and local media platforms.
                  *blah-blahblah… fig leaves* <br />
                  <br />
                  Now. <br />
                  <br />
                  All we really want to do is help new and select businesses
                  with gargantuan desires achieve disruptive success in the
                  Luxury space. We are disruptive by nature, that is how you
                  know it is us. <br />
                  <br />
                  We don’t work with everybody. We won’t work with anybody. We
                  really have to like you, and you truly have to call us
                  friends.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer
        className="sticky"
        showRightArrow
        onRightButtonClick={onRightButtonClick}
      />
    </>
  );
};

export default withRouter(About);
