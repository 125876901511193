import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { animated } from "react-spring/renderprops";
import "./services.scss";
import Footer from "../commons/footer";
import GoldPackageImg from "../../assets/images/packages/gold-packages.png";
import SilverPackageImg from "../../assets/images/packages/silver-packages.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Onboarding139thAvenue = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>139th Avenue Onboarding</title>
        <meta
          name="description"
          content="PS: Please note, because each client presents unique
          perspectives to their challenges, and no experience of any
          problem is the same, we do not expatiate on our standard
          service descriptions here but rely primarily on our thorough
          pre-assessments, listening and follow up consultations to
          develop specific problem-solving deliverables for our clients."
        />
      </Helmet>
      <animated.div className="app-container">
        <div className="page-wrapper">
          <Container fluid>
            <Row>
              <Col md={12} lg={1} pull-right></Col>
              <Col md={12} lg={3} pull-right>
                <h1>139th Avenue Onboarding</h1>
              </Col>
              <Col md={12} lg={5}>
                <p className="what-we-do-well-paragraph">
                We are building a physical outlet in Dubai and E-Commerce platform for emerging, new
entrant and independent brands in the retail industry.
                </p>
                <p className="what-we-do-well-paragraph">
                Our storefront and experience outlet is located in the heart of Downtown Dubai and its
Financial Centre. With a daily footfall of over 29,000 visitors in our location, we are
surrounded by over 4000 companies, award-winning restaurants, night lounges and a view
of the world's tallest building - the Burj Khalifa.
                </p>
                <p className="what-we-do-well-paragraph">
                Our current sales and distribution platform channels will cover all of the United Arab
Emirates (UAE) and all other GCC neighbours (Oman, Bahrain, Kingdom of Saudi Arabia,
Qatar and Kuwait).
                </p>
                <p className="what-we-do-well-paragraph">
                To begin your process of onboarding your brand on our E-Commerce platform and physical
store outlet, fill the form to join the Wait List at <a href="https://www.139thavenue.com/" className="very-bold" target="_blank" rel="noreferrer">139th Avenue</a>.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </animated.div>
      <Footer className="sticky" />
    </>
  );
};

export default Onboarding139thAvenue;
