import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./services.scss";
import Footer from "../commons/footer";
import image1 from "../../assets/background-images/BACKGROUNDcc 1.png";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const PlatinumPackage = () => {
  const history = useHistory();
  const onLeftButtonClick = () => {
    history.push("/services/what-we-do-well/gold-service");
  };
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Platinum Service - BDIC</title>
        <meta
          name="description"
          content="Our platinum service can at this moment only accept two (2)
          carefully and thoroughly selected clients per year. We don’t
          advertise our service descriptions here."
        />
      </Helmet>
      <div className="app-container">
        <div className="page-wrapper">
          <Container fluid>
            <Row>
              <Col md={12} lg={2} pull-right>
                <h1>Platinum Service</h1>
              </Col>
              <Col md={12} lg={1}></Col>
              <Col md={12} lg={4} pull-right>
                <p className="very-bold">Brand Value Ecosystem Generation –</p>
                <p className="italics">
                  Our Focus: Your people. Your products. Your systems. Your
                  services.
                </p>
                <br />
                <p className="italics">
                  Our platinum service can at this moment only accept two (2)
                  carefully and thoroughly selected clients per year. We don’t
                  advertise our service descriptions here.
                </p>
              </Col>
            </Row>
          </Container>
          <div className="edge-image">
            <img src={image1} alt="" />
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        showLeftArrow
        onLeftButtonClick={onLeftButtonClick}
      />
    </>
  );
};

export default PlatinumPackage;
