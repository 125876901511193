import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../../../assets/images/icons/menu-icon.svg";

const MenuList = (props) => {
  return !props.hide ? (
    <>
      <li className="menu-item">
        <div>Fundamentals</div>
        <ul className="menu-item-dropdown">
          <li>
            <div>
              <Link to="/about">About</Link>
            </div>
          </li>
          <li>
            <div>Principles</div>
            <ul>
              <li>
                <div>
                  <Link to="/our-philosophy">Philosophy & Values</Link>
                </div>
              </li>
              <li>
                <div>
                  <Link to="/our-promise">Our Promise</Link>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li className="menu-item">
        <div>Services</div>
        <ul className="menu-item-dropdown">
          <li>
            <div>
              <Link to="/what-we-do-well">WHAT WE DO TOO WELL</Link>
            </div>
          </li>
          <li>
            <div>
              <Link to="/approach">APPROACH</Link>
            </div>
          </li>
          <li>
            <div>
              <Link to="/partners">PARTNERS</Link>
            </div>
          </li>
        </ul>
      </li>
      <li className="menu-item">
        <div>Contact</div>
        <ul className="menu-item-dropdown">
          <li>
            <div>
              <Link to="/appointments">APPOINTMENT</Link>
            </div>
          </li>
          <li>
            <div>
              <Link to="/request-brochure">REQUEST BROCHURE</Link>
            </div>
          </li>
          <li>
            <div>
              <Link to="/faq">FAQ</Link>
            </div>
          </li>
        </ul>
      </li>
    </>
  ) : null;
};

const Header = (props) => {
  const { hideMenu } = props;
  const [showMenu, setShowMenu] = React.useState(false);
  const menuRef = React.useRef();
  const menuIconRef = React.useRef();

  const toggleMenu = () => () => {
    window.event.preventDefault();
    setShowMenu(!showMenu);
  };

  React.useEffect(() => {
    return () => {
      setShowMenu(false);
    };
  }, []);

  React.useEffect(() => {
    menuRef.current.style.setProperty(
      "display",
      showMenu ? "flex" : "none",
      "important"
    );
  }, [showMenu]);

  return (
    <nav {...props}>
      <div className="menu-container">
        {!hideMenu && (
          <div
            className="mobile-menu-icon"
            onClick={toggleMenu()}
            ref={menuIconRef}
          >
            <MenuIcon stroke={"black"} />
          </div>
        )}
        <Link to="/">
          <div className="logo"></div>
        </Link>
        <div className="menu-wrapper">
          <div id="mobile-menu" ref={menuRef}>
            <ul className="menu">
              <MenuList hide={hideMenu} />
            </ul>
          </div>
          <div id="desktop-menu">
            <ul className="menu">
              <MenuList hide={hideMenu} />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
