import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import "../Contacts/contacts.scss";
import Footer from "../commons/footer";
import { Link, useParams } from "react-router-dom";
import AppDark from "../AppDark";
import { validateEmail } from "../../utils/validateEmail";
import { animated } from "react-spring";
import { Helmet } from "react-helmet";

const INITIAL_FORM_DATA = {
  fullname: "",
  email: "",
  reason: "",
};

const RequestClientMoreDetails = (props) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [currentForm, setCurrentForm] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    loadClientDetails();
  }, []);

  const loadClientDetails = async () => {
    try {
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/clients/${id}`
      );
      setFormData({ ...formData, clientName: response.data?.name ?? "" });
      console.log(response);
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
    }
  };

  const onChangeText = (name) => (evt) => {
    setFormData({
      ...formData,
      [name]: evt.target.value,
    });
    if (isEmpty(evt.target.value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: `This field is required and cannot be empty.`,
      }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const requiredFieldsAreNotEmpty = (requiredInputs) => {
    let errorCount = 0;
    requiredInputs.forEach((input) => {
      if (input === "email") {
        if (!validateEmail(formData[input])) {
          setFormErrors((prev) => ({
            ...prev,
            [input]: `Email is not valid`,
          }));
          errorCount++;
        }
      }
      if (isEmpty(formData[input])) {
        setFormErrors((prev) => ({
          ...prev,
          [input]: `This field is required and cannot be empty.`,
        }));
        errorCount++;
      }
    });

    if (errorCount > 0) {
      return false;
    }

    errorCount = 0;
    setFormErrors({});
    return true;
  };

  const handleFormSubmitPerState = () => {
    const resetForm = () => {
      setFormData(INITIAL_FORM_DATA);
      setCurrentForm(0);
    };
    if (requiredFieldsAreNotEmpty(["fullname", "email", "reason"])) {
      setSubmitting(true);

      try {
        setSubmitting(true);
        // axios.post("/api/sendmail", formData);
        axios
          .post("/.netlify/functions/request-more-about-client", formData)
          .then((res) => {
            setSubmitting(false);
            if (res.data.result !== "success") {
              alert("Email failed to send. Please try again.");
              setTimeout(() => {
                resetForm();
              }, 6000);
            } else {
              alert(
                "Thank you for requesting for more information about our client. Please check your email in 1-2 business days."
              );
              setTimeout(() => {
                resetForm();
              }, 6000);
            }
          })
          .catch((err) => {
            alert("Oops, an unexpected error occured. Please try again.");
            setSubmitting(false);
          });
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  const onSubmit = () => {};

  const renderForm = () => {
    switch (currentForm) {
      case 0:
        return (
          <div className="rb-landing-container">
            <Row>
              <Col md={12} lg={5} pull-right>
                <div className="appointment-title-container">
                  <h1
                    className="primary-color-dark pt-0 pb-1 mb-0"
                    style={{ textAlign: "left" }}
                  >
                    Request More About Our Client
                  </h1>
                  <p
                    className="primary-color-dark pt-1 mb-3"
                    style={{ textAlign: "left" }}
                  >
                    {formData.clientName}
                  </p>
                </div>
              </Col>
              <Col md={12} lg={6} pull-right>
                <div className="rb-form-wrapper">
                  <div className="form-container">
                    <p className="input-title">Full Name:</p>
                    <div className="input-container">
                      <input
                        className="input"
                        onChange={onChangeText("fullname")}
                        value={formData.fullname}
                      />
                      {formErrors["fullname"] && (
                        <p className="form-error-text">
                          ${formErrors["fullname"]}
                        </p>
                      )}
                    </div>

                    <p className="input-title">EMAIL:</p>
                    <div className="input-container">
                      <input
                        className="input"
                        onChange={onChangeText("email")}
                        value={formData.email}
                      />
                      {formErrors["email"] && (
                        <p className="form-error-text">
                          ${formErrors["email"]}
                        </p>
                      )}
                    </div>

                    <p className="input-title">
                      Why do you wish to know more about the client?:
                    </p>
                    <div className="input-container">
                      <textarea
                        className="input faq-input"
                        rows="4"
                        cols="60"
                        name="text"
                        onChange={onChangeText("reason")}
                        value={formData.reason}
                      ></textarea>
                      {formErrors["reason"] && (
                        <p className="form-error-text">
                          ${formErrors["reason"]}
                        </p>
                      )}
                    </div>
                    <div
                      className="btn-wrapper request-br-btn-wrapper"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <div
                        className="continue-btn rb-request-btn"
                        onClick={handleFormSubmitPerState}
                      >
                        <Link>
                          {submitting
                            ? "Requesting..."
                            : "Request For More Details"}
                          &nbsp;
                          <svg
                            width="46"
                            height="15"
                            viewBox="0 0 46 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                              fill="black"
                            />
                            <path
                              d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                              fill="black"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <AppDark style={props.style}>
      <animated.div className="app-container contact-container">
        <Container fluid>
          <form onSubmit={onSubmit}>{renderForm()}</form>
        </Container>
      </animated.div>
      <Footer className="sticky dark" />
    </AppDark>
  );
};

export default RequestClientMoreDetails;
