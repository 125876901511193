import React, { useCallback, useEffect, useRef, useState } from "react";
import "./live.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useWindowSize } from "../../utils/useWindowResize";
import { sortEveryNth } from "../../utils/sortEveryNth";
import { ReactComponent as LongRightArrow } from "../../assets/images/icons/long-right-arrow.svg";
import { ReactComponent as FooterRightArrow } from "../../assets/images/icons/right-arrow.svg";

import Gallery from "react-grid-gallery";
import Footer from "../commons/footer";
import grid1 from "../../assets/images/live/1.png";
import grid2 from "../../assets/images/live/2.png";
import grid3 from "../../assets/images/live/3.png";
import grid4 from "../../assets/images/live/4.png";
import grid5 from "../../assets/images/live/5.png";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { getAllUrlParams } from "../../utils/getAllUrlParams";
import { post } from "jquery";
import { Helmet } from "react-helmet";

const GalleryWrapper = (props) => {
  return (
    <div {...props} className="gallery-wrapper live-gallery-wrapper">
      {props.children}
    </div>
  );
};

const GalleryImage = (props) => {
  return (
    <div
      className={`gallery-image-wrapper gallery-image-wrapper-${
        props.index + 1
      }`}
    >
      <div
        className="gallery-image"
        style={{
          background: `linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),url('${props.data.featured_image_url}')`,
        }}
      >
        <p>{props.data.title}</p>
        <Link to={`/live/${props.data.id}`}>
          <p className="very-bold">
            Read more &nbsp;
            <LongRightArrow />
          </p>
        </Link>
      </div>
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Live = () => {
  const [width] = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sortedImageData, setSortedImageData] = useState([]);
  const [paginationLinks, setPaginationLinks] = useState({});

  const history = useHistory();

  const query = useQuery();
  const pageNumber = query.get("page") ?? 1;
  const categoryName = query.get("category") ?? "all";

  // useEffect(() => {
  //   loadBlog();
  //   loadCategories();
  // }, []);

  useEffect(() => {
    handleCategoryChange(categoryName);
  }, [categoryName]);

  const handleCategoryChange = async (categoryName) => {
    Promise.all([loadBlog(categoryName), loadCategories()]);
  };

  useEffect(() => {
    if (width > 1366) {
      setSortedImageData(sortEveryNth(posts, 5));
    } else if (width > 1024 && width <= 1366) {
      setSortedImageData(sortEveryNth(posts, 3));
    } else if (width > 800 && width <= 1024) {
      setSortedImageData(sortEveryNth(posts, 2));
    } else {
      setSortedImageData(posts);
    }
  }, [width, posts]);

  const loadCategories = async () => {
    try {
      const categoriesResponse = await axios.get(
        "https://admin.bdicofficial.com/api/categories"
      );
      setCategories(categoriesResponse.data.data);
    } catch (error) {
      // alert(`An error occured while fetching posts - ${error.message}`);
      setIsLoading(false);
    }
  };

  const loadBlog = async (categoryName = "all") => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/posts?page=${pageNumber}`
      );
      const publishedPosts = response.data?.data.filter(
        (post) => post.is_published
      );
      setPosts(publishedPosts ?? []);
      if (categoryName !== "all") {
        setPosts(
          publishedPosts.filter((post) => post.category.name === categoryName)
        );
      }
      setPaginationLinks(response.data?.links);
      setIsLoading(false);
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
      setIsLoading(false);
    }
  };

  const onRightButtonClick = () => {
    const getParams = getAllUrlParams(paginationLinks.next);
    history.push(`/live?page=${getParams.page ?? 1}`);
    window.location.reload();
  };

  const onLeftButtonClick = () => {
    if (!isEmpty(paginationLinks.prev)) {
      const getParams = getAllUrlParams(paginationLinks.prev);
      history.push(`/live?page=${getParams.page ?? 1}`);
      window.location.reload();
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Live - BDIC</title>
        <meta
          name="description"
          content="See a selection of our latest news, events and projects from
          BDIC. Feel free to write back to us if you see many things that
          pique your interest, plus if you would like to be part of our
          works and events next time."
        />
      </Helmet>
      <div className="app-container">
        <div className="page-wrapper">
          <div className="live-section">
            <div className="live-header-section">
              <div className="live-header-menu">
                <p className="live-header-title">Live</p>
                <p className={`${categoryName === "all" && "very-bold"}`}>
                  <Link to={`/live?&category=all`}>All</Link>
                </p>
                {categories.map((category) => (
                  <p
                    className={`${
                      categoryName === category.name && "very-bold"
                    }`}
                  >
                    <Link to={`/live?&category=${category.name}`}>
                      {category.name}
                    </Link>
                  </p>
                ))}
              </div>

              <p className="live-header-description">
                See a selection of our latest news, events and projects from
                BDIC. Feel free to write back to us if you see many things that
                pique your interest, plus if you would like to be part of our
                works and events next time.
              </p>
            </div>
            {isLoading && (
              <Spinner animation="grow" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {!isEmpty(sortedImageData) && (
              <GalleryWrapper>
                {sortedImageData.map((data, index) => (
                  <GalleryImage key={data.id} data={data} index={index} />
                ))}
              </GalleryWrapper>
            )}
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        showRightArrow
        showLeftArrow={pageNumber !== "1"}
        onRightButtonClick={onRightButtonClick}
        onLeftButtonClick={onLeftButtonClick}
        rightArrowClass="live-right-arrow"
        leftArrowClass="live-left-arrow"
      />
    </>
  );
};

export default Live;
