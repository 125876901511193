import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import "./contacts.scss";
import Footer from "../commons/footer";
import { Link } from "react-router-dom";
import AppDark from "../AppDark";
import { validateEmail } from "../../utils/validateEmail";
import axios from "axios";
import { animated } from "react-spring";
import { Helmet } from "react-helmet";

const INITIAL_FORM_DATA = {
  email: "",
  describe_question: "",
};

const FAQ = (props) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [currentForm, setCurrentForm] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onChangeText = (name) => (evt) => {
    setFormData({
      ...formData,
      [name]: evt.target.value,
    });
  };

  const requiredFieldsAreNotEmpty = (requiredInputs) => {
    let errorCount = 0;
    requiredInputs.forEach((input) => {
      if (input === "email") {
        if (!validateEmail(formData[input])) {
          setFormErrors((prev) => ({
            ...prev,
            [input]: `Email is not valid`,
          }));
          errorCount++;
        }
      }
      if (isEmpty(formData[input])) {
        setFormErrors((prev) => ({
          ...prev,
          [input]: `This field is required and cannot be empty.`,
        }));
        errorCount++;
      }
    });

    if (errorCount > 0) {
      return false;
    }

    errorCount = 0;
    setFormErrors({});
    return true;
  };

  const handleFormSubmitPerState = () => {
    const resetForm = () => {
      setFormData(INITIAL_FORM_DATA);
      setCurrentForm(0);
    };
    if (requiredFieldsAreNotEmpty(["email", "describe_question"])) {
      setSubmitting(true);

      try {
        setSubmitting(true);
        axios
          .post("/.netlify/functions/faq", formData)
          .then((res) => {
            setSubmitting(false);
            if (res.data.result !== "success") {
              alert("Email failed to send. Please try again.");
              setTimeout(() => {
                resetForm();
              }, 6000);
            } else {
              alert("A team member will respond to your question. Thank you.");
              setTimeout(() => {
                resetForm();
              }, 6000);
            }
          })
          .catch((err) => {
            alert("Oops, an unexpected error occured. Please try again.");
            setSubmitting(false);
          });
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  const onSubmit = () => {};

  const renderForm = () => {
    switch (currentForm) {
      case 0:
        return (
          <div className="appointments-landing-container">
            <Row>
              <Col md={12} lg={5} pull-right>
                <div className="appointment-title-container">
                  <h2
                    className="primary-color-dark pt-0"
                    style={{ textAlign: "left" }}
                  >
                    Special Guest, <br />
                    how can we help you?
                  </h2>
                </div>
              </Col>
              <Col md={12} lg={6} pull-right>
                <div className="form-wrapper faq-form-wrapper">
                  <div className="form-container">
                    <p className="input-title">Your Email:</p>
                    <div className="input-container">
                      <input
                        className="input faq-input"
                        onChange={onChangeText("email")}
                        value={formData.current_location}
                        name="email"
                      />
                      {formErrors["email"] && (
                        <p className="form-error-text">{formErrors["email"]}</p>
                      )}
                    </div>
                    <p className="input-title">Describe your question…</p>
                    <div className="input-container">
                      <textarea
                        className="input faq-input"
                        rows="4"
                        cols="60"
                        name="text"
                        onChange={onChangeText("describe_question")}
                        value={formData.describe_question}
                      ></textarea>
                      {formErrors["describe_question"] && (
                        <p className="form-error-text">
                          {formErrors["describe_question"]}
                        </p>
                      )}
                    </div>
                    <div
                      className="btn-wrapper faq-btn-wrapper"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <div
                        className="continue-btn"
                        onClick={handleFormSubmitPerState}
                      >
                        <Link>
                          {submitting ? "Submitting..." : "Submit"} &nbsp;
                          <svg
                            width="46"
                            height="15"
                            viewBox="0 0 46 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                              fill="black"
                            />
                            <path
                              d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                              fill="black"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                    <p className="very-bold faq-title">
                      Frequently Asked Questions :
                    </p>
                    <p className="very-bold faq-sub-title">Overseas Client</p>
                    <Accordion>
                      <Card className="faq-card">
                        <Card.Header className="faq-card-header">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            >> Scheduling a listening session
                          </Accordion.Toggle>
                        </Card.Header>
                        {/* <Accordion.Collapse eventKey="0">
                          <Card.Body>Hello! I'm the body</Card.Body>
                        </Accordion.Collapse> */}
                      </Card>
                      <Card className="faq-card">
                        <Card.Header className="faq-card-header faq-card-header-with-margin">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                          >
                            >> Payment
                          </Accordion.Toggle>
                        </Card.Header>
                        {/* <Accordion.Collapse eventKey="1">
                          <Card.Body>Hello! I'm another body</Card.Body>
                        </Accordion.Collapse> */}
                      </Card>
                    </Accordion>
                    <p className="very-bold faq-sub-title">Home Client</p>
                    <Accordion>
                      <Card className="faq-card">
                        <Card.Header className="faq-card-header">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            >> Scheduling a listening session
                          </Accordion.Toggle>
                        </Card.Header>
                        {/* <Accordion.Collapse eventKey="0">
                          <Card.Body>Hello! I'm the body</Card.Body>
                        </Accordion.Collapse> */}
                      </Card>
                    </Accordion>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <AppDark style={props.style}>
      <Helmet>
        <html lang="en" />
        <title>FAQ - BDIC</title>
        <meta
          name="description"
          content="Special Guest, how can we help you?"
        />
      </Helmet>
      <animated.div className="app-container contact-container">
        <Container fluid>
          <form onSubmit={onSubmit}>{renderForm()}</form>
        </Container>
      </animated.div>
      <Footer className="sticky dark" />
    </AppDark>
  );
};

export default FAQ;
