import isString from "lodash/isString";

export function truncate(str, n) {
  if (!isString(str)) return;
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export function truncateMiddle(fullStr, strLen, separator) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || "...";

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
}
