import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import "./contacts.scss";
import Footer from "../commons/footer";
import { Link } from "react-router-dom";
import AppDark from "../AppDark";
import { validateEmail } from "../../utils/validateEmail";
import { animated } from "react-spring";
import { Helmet } from "react-helmet";

const INITIAL_FORM_DATA = {
  email: "",
  firstname: "",
  lastname: "",
  years_in_business: "",
  number_of_employees: "",
  type_of_industry: "",
  job_title: "",
  nationality_2: "",
  business_phone: "",
  service_required: "",
  challenge_faced: "",
  current_location: "",
  nationality_1: "",
};
const Appointments = (props) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [currentForm, setCurrentForm] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onChangeText = (name) => (evt) => {
    setFormData({
      ...formData,
      [name]: evt.target.value,
    });
    if (isEmpty(evt.target.value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: `This field is required and cannot be empty.`,
      }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const requiredFieldsAreNotEmpty = (requiredInputs) => {
    let errorCount = 0;
    requiredInputs.forEach((input) => {
      if (input === "email") {
        if (!validateEmail(formData[input])) {
          setFormErrors((prev) => ({
            ...prev,
            [input]: `Email is not valid`,
          }));
          errorCount++;
        }
      }
      if (isEmpty(formData[input])) {
        setFormErrors((prev) => ({
          ...prev,
          [input]: `This field is required and cannot be empty.`,
        }));
        errorCount++;
      }
    });

    if (errorCount > 0) {
      return false;
    }

    errorCount = 0;
    setFormErrors({});
    return true;
  };

  const handleFormSubmitPerState = (form) => () => {
    switch (form) {
      case 0:
        if (requiredFieldsAreNotEmpty(["email"])) {
          setCurrentForm(1);
        }

        break;
      case 1:
        let requiredFIelds = [
          "firstname",
          "lastname",
          "years_in_business",
          "number_of_employees",
          "type_of_industry",
          "job_title",
        ];

        if (formData.type_of_industry === "Other") {
          requiredFIelds.push("other_type_of_industry");
        }
        if (requiredFieldsAreNotEmpty(requiredFIelds)) {
          setCurrentForm(2);
        }
        break;
      case 2:
        const resetForm = () => {
          setFormData(INITIAL_FORM_DATA);
          setCurrentForm(0);
        };

        const areWeBeforeJan112021 = () => {
          const dateFrom = new Date();
          const dateTo = new Date(2021, 0, 11);
          return dateFrom < dateTo;
        };

        if (
          requiredFieldsAreNotEmpty([
            "service_required",
            "challenge_faced",
            "current_location",
            "nationality_1",
            "nationality_2",
            "business_phone",
          ])
        ) {
          setSubmitting(true);

          try {
            setSubmitting(true);
            // axios.post("/api/sendmail", formData);
            axios
              .post("/.netlify/functions/appointments", formData)
              .then((res) => {
                setSubmitting(false);
                if (res.data.result !== "success") {
                  alert("Email failed to send. Please try again.");
                  setTimeout(() => {
                    resetForm();
                  }, 6000);
                } else {
                  alert(
                    `Thank you for making an appointment request. We would contact via email with updates in 2-3 business days.${
                      areWeBeforeJan112021()
                        ? "\n PS: we are on holiday now till January 11, 2021."
                        : ""
                    }`
                  );
                  resetForm();
                }
              })
              .catch((err) => {
                // PS: we are on holiday now till January 11, 2021.
                // from now till January
                setSubmitting(false);
              });
          } catch (error) {
            setSubmitting(false);
          }
        }
        break;
      default:
        break;
    }
  };

  const onSubmit = () => {};

  const renderForm = () => {
    switch (currentForm) {
      case 0:
        return (
          <div className="appointments-landing-container">
            <Row>
              <Col md={12} lg={5} pull-right>
                <h1
                  className="primary-color-dark pt-0"
                  style={{ textAlign: "center" }}
                >
                  Appointment
                </h1>
              </Col>
              <Col md={12} lg={6} pull-right>
                <p className="primary-color-dark pt-1 mb-3">
                  To process for an appointment, please enter your email:
                </p>
                <p className="input-title">REACHABLE EMAIL:</p>
                <div className="input-container mb-3">
                  <input
                    className="input contact-input"
                    onChange={onChangeText("email")}
                    value={formData.email}
                    name="email"
                  />
                  {formErrors["email"] && (
                    <p className="form-error-text">{formErrors["email"]}</p>
                  )}
                </div>
                <p className="primary-color-dark input-small-text mb-3">
                  By completing and submitting this form, you understand and
                  agree that use of BDIC website is subject to the
                  bdicofficial.com Terms of Use and Privacy Policy. Including
                  the fact that BDIC may share your information collected on
                  this website in connection with the service(s) you require to
                  our partners and third party entities that also provide
                  services to our BDIC Clients.
                </p>
                <div
                  className="continue-btn contact-continue-btn"
                  onClick={handleFormSubmitPerState(0)}
                >
                  <Link>
                    Continue &nbsp;
                    <svg
                      width="46"
                      height="15"
                      viewBox="0 0 46 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                        fill="black"
                      />
                      <path
                        d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                        fill="black"
                      />
                    </svg>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        );

      case 1:
        return (
          <div className="form-wrapper">
            <div className="form-container">
              <p className="input-title">FIRST NAME:</p>
              <div className="input-container">
                <input
                  className="input contact-input"
                  onChange={onChangeText("firstname")}
                  value={formData.firstname}
                  name="firstname"
                />
                {formErrors["firstname"] && (
                  <p className="form-error-text">{formErrors["firstname"]}</p>
                )}
              </div>

              <p className="input-title">LAST NAME:</p>
              <div className="input-container">
                <input
                  className="input contact-input"
                  onChange={onChangeText("lastname")}
                  value={formData.lastname}
                  name="lastname"
                />
                {formErrors["lastname"] && (
                  <p className="form-error-text">{formErrors["lastname"]}</p>
                )}
              </div>
              <p className="input-title">YEARS IN BUSINESS:</p>

              <div className="input-container">
                <input
                  className="input contact-input"
                  onChange={onChangeText("years_in_business")}
                  value={formData.years_in_business}
                  name="years_in_business"
                  type="number"
                />
                {formErrors["years_in_business"] && (
                  <p className="form-error-text">
                    {formErrors["years_in_business"]}
                  </p>
                )}
              </div>
              <p className="input-title">NUMBER OF EMPLOYEES:</p>
              <div className="input-container">
                <input
                  className="input contact-input"
                  onChange={onChangeText("number_of_employees")}
                  value={formData.number_of_employees}
                  name="number_of_employees"
                  type="number"
                />
                {formErrors["number_of_employees"] && (
                  <p className="form-error-text">
                    {formErrors["number_of_employees"]}
                  </p>
                )}
              </div>
              <p className="input-title">TYPE OF INDUSTRY:</p>
              <div className="input-container">
                <select
                  className="input contact-input"
                  onChange={onChangeText("type_of_industry")}
                  value={formData.type_of_industry}
                  name="type_of_industry"
                >
                  <option></option>
                  <option>Arts</option>
                  <option>Fashion</option>
                  <option>Fragrance</option>
                  <option>Accessories</option>
                  <option>Recreation</option>
                  <option>Hospitality</option>
                  <option>Other</option>
                </select>
                {formErrors["type_of_industry"] && (
                  <p className="form-error-text">
                    {formErrors["type_of_industry"]}
                  </p>
                )}
              </div>
              {formData.type_of_industry === "Other" && (
                <>
                  <p className="input-title">NAME INDUSTRY:</p>
                  <div className="input-container">
                    <input
                      className="input contact-input"
                      onChange={onChangeText("other_type_of_industry")}
                      value={formData.other_type_of_industry}
                      name="other_type_of_industry"
                    />
                    {formErrors["other_type_of_industry"] && (
                      <p className="form-error-text">
                        {formErrors["other_type_of_industry"]}
                      </p>
                    )}
                  </div>
                </>
              )}
              <p className="input-title">JOB TITLE / DESIGNATION:</p>
              <div className="input-container mb-3">
                <input
                  className="input contact-input"
                  onChange={onChangeText("job_title")}
                  value={formData.job_title}
                  name="job_title"
                />
                {formErrors["job_title"] && (
                  <p className="form-error-text">{formErrors["job_title"]}</p>
                )}
              </div>
              <div className="btn-wrapper with-back-btn mt-2">
                <Row>
                  <Col md={12} lg={6}>
                    <div className="back-btn" onClick={() => setCurrentForm(0)}>
                      <Link>
                        <svg
                          width="31"
                          height="10"
                          viewBox="0 0 88 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0)">
                            <path
                              d="M14.3786 29L15.4785 27.8147L2.19997 14.4605L15.4785 1.10627L14.3786 0L-3.05176e-05 14.4605L14.3786 29Z"
                              fill="black"
                            />
                            <path
                              d="M1.10001 13.6703H88V15.2507H1.10001V13.6703Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect
                                width="88"
                                height="29"
                                fill="white"
                                transform="matrix(-1 0 0 1 88 0)"
                              />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        &nbsp; Back
                      </Link>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div
                      className="continue-btn contact-continue-btn"
                      onClick={handleFormSubmitPerState(1)}
                    >
                      <Link>
                        Continue &nbsp;
                        <svg
                          width="46"
                          height="15"
                          viewBox="0 0 46 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                            fill="black"
                          />
                          <path
                            d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                            fill="black"
                          />
                        </svg>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="form-wrapper">
            <div className="form-container">
              <p className="input-title">SERVICE REQUIRED:</p>
              <div className="input-container">
                <select
                  className="input contact-input"
                  onChange={onChangeText("service_required")}
                  value={formData.service_required}
                  name="service_required"
                >
                  <option></option>
                  <option>Gold</option>
                  <option>Platinum</option>
                </select>
                {formErrors["service_required"] && (
                  <p className="form-error-text">
                    {formErrors["service_required"]}
                  </p>
                )}
              </div>
              <p className="input-title">
                WHAT CHALLENGE(S) ARE YOU TRYING TO SOLVE?
              </p>
              <div className="input-container">
                <textarea
                  className="input contact-input"
                  rows="3"
                  cols="60"
                  onChange={onChangeText("challenge_faced")}
                  value={formData.challenge_faced}
                  name="challenge_faced"
                ></textarea>
                {formErrors["challenge_faced"] && (
                  <p className="form-error-text">
                    {formErrors["challenge_faced"]}
                  </p>
                )}
              </div>
              <p className="input-title">CURRENT LOCATION:</p>
              <div className="input-container">
                <input
                  className="input contact-input"
                  onChange={onChangeText("current_location")}
                  value={formData.current_location}
                  name="current_location"
                />
                {formErrors["current_location"] && (
                  <p className="form-error-text">
                    {formErrors["current_location"]}
                  </p>
                )}
              </div>
              <p className="input-title">NATIONALITY 1:</p>
              <div className="input-container">
                <select
                  className="input contact-input"
                  onChange={onChangeText("nationality_1")}
                  name="nationality_1"
                  value={formData.nationality_1}
                >
                  <option></option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua And Barbuda">
                    Antigua And Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia And Herzegovina">
                    Bosnia And Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (keeling) Islands">
                    Cocos (keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic Of The">
                    Congo, The Democratic Republic Of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (malvinas)">
                    Falkland Islands (malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island And Mcdonald Islands">
                    Heard Island And Mcdonald Islands
                  </option>
                  <option value="Holy See (vatican City State)">
                    Holy See (vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic Of">
                    Iran, Islamic Republic Of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakstan">Kazakstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic Of">
                    Korea, Democratic People's Republic Of
                  </option>
                  <option value="Korea, Republic Of">Korea, Republic Of</option>
                  <option value="Kosovo">Kosovo</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia, The Former Yugoslav Republic Of">
                    Macedonia, The Former Yugoslav Republic Of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States Of">
                    Micronesia, Federated States Of
                  </option>
                  <option value="Moldova, Republic Of">
                    Moldova, Republic Of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts And Nevis">
                    Saint Kitts And Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre And Miquelon">
                    Saint Pierre And Miquelon
                  </option>
                  <option value="Saint Vincent And The Grenadines">
                    Saint Vincent And The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome And Principe">
                    Sao Tome And Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia And The South Sandwich Islands">
                    South Georgia And The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard And Jan Mayen">
                    Svalbard And Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan, Province Of China">
                    Taiwan, Province Of China
                  </option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic Of">
                    Tanzania, United Republic Of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad And Tobago">
                    Trinidad And Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks And Caicos Islands">
                    Turks And Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.s.">
                    Virgin Islands, U.s.
                  </option>
                  <option value="Wallis And Futuna">Wallis And Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                {formErrors["nationality_1"] && (
                  <p className="form-error-text">
                    {formErrors["nationality_1"]}
                  </p>
                )}
              </div>
              <p className="input-title">NATIONALITY 2:</p>
              <div className="input-container">
                <select
                  className="input contact-input"
                  onChange={onChangeText("nationality_2")}
                  name="nationality_2"
                  value={formData.nationality_2}
                >
                  <option></option>
                  <option value="N/A">Not Applicable</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua And Barbuda">
                    Antigua And Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia And Herzegovina">
                    Bosnia And Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (keeling) Islands">
                    Cocos (keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic Of The">
                    Congo, The Democratic Republic Of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (malvinas)">
                    Falkland Islands (malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island And Mcdonald Islands">
                    Heard Island And Mcdonald Islands
                  </option>
                  <option value="Holy See (vatican City State)">
                    Holy See (vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic Of">
                    Iran, Islamic Republic Of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakstan">Kazakstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic Of">
                    Korea, Democratic People's Republic Of
                  </option>
                  <option value="Korea, Republic Of">Korea, Republic Of</option>
                  <option value="Kosovo">Kosovo</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia, The Former Yugoslav Republic Of">
                    Macedonia, The Former Yugoslav Republic Of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States Of">
                    Micronesia, Federated States Of
                  </option>
                  <option value="Moldova, Republic Of">
                    Moldova, Republic Of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts And Nevis">
                    Saint Kitts And Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre And Miquelon">
                    Saint Pierre And Miquelon
                  </option>
                  <option value="Saint Vincent And The Grenadines">
                    Saint Vincent And The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome And Principe">
                    Sao Tome And Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia And The South Sandwich Islands">
                    South Georgia And The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard And Jan Mayen">
                    Svalbard And Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan, Province Of China">
                    Taiwan, Province Of China
                  </option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic Of">
                    Tanzania, United Republic Of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad And Tobago">
                    Trinidad And Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks And Caicos Islands">
                    Turks And Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.s.">
                    Virgin Islands, U.s.
                  </option>
                  <option value="Wallis And Futuna">Wallis And Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                {formErrors["nationality_2"] && (
                  <p className="form-error-text">
                    {formErrors["nationality_2"]}
                  </p>
                )}
              </div>
              <p className="input-title">BUSINESS PHONE:</p>
              <div className="input-container mb-3">
                <input
                  className="input contact-input"
                  type="tel"
                  onChange={onChangeText("business_phone")}
                  name="business_phone"
                  value={formData.business_phone}
                />
                {formErrors["business_phone"] && (
                  <p className="form-error-text">
                    {formErrors["business_phone"]}
                  </p>
                )}
              </div>
              <div className="btn-wrapper with-back-btn mt-2">
                <Row>
                  <Col md={12} lg={6}>
                    <div className="back-btn" onClick={() => setCurrentForm(1)}>
                      <Link>
                        <svg
                          width="31"
                          height="10"
                          viewBox="0 0 88 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0)">
                            <path
                              d="M14.3786 29L15.4785 27.8147L2.19997 14.4605L15.4785 1.10627L14.3786 0L-3.05176e-05 14.4605L14.3786 29Z"
                              fill="black"
                            />
                            <path
                              d="M1.10001 13.6703H88V15.2507H1.10001V13.6703Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect
                                width="88"
                                height="29"
                                fill="white"
                                transform="matrix(-1 0 0 1 88 0)"
                              />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        &nbsp; Back
                      </Link>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div
                      className="continue-btn contact-continue-btn"
                      onClick={handleFormSubmitPerState(2)}
                    >
                      <Link>
                        {submitting ? "Submitting..." : "Submit"} &nbsp;
                        <svg
                          width="46"
                          height="15"
                          viewBox="0 0 46 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                            fill="black"
                          />
                          <path
                            d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                            fill="black"
                          />
                        </svg>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <AppDark style={props.style}>
      <Helmet>
        <html lang="en" />
        <title>Appointments - BDIC</title>
        <meta
          name="description"
          content="By completing and submitting this form, you understand and
          agree that use of BDIC website is subject to the
          bdicofficial.com Terms of Use and Privacy Policy."
        />
      </Helmet>
      <animated.div className="app-container contact-container">
        <Container fluid>
          <form onSubmit={onSubmit} netlify>
            {renderForm()}
          </form>
        </Container>
      </animated.div>
      <Footer className="sticky dark" />
    </AppDark>
  );
};

export default Appointments;
