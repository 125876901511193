import React, { useCallback, useEffect, useRef, useState } from "react";
import "./clients.scss";
import isEmpty from "lodash/isEmpty";
import { Lightbox, ModalImage } from "react-modal-image";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useWindowSize } from "../../utils/useWindowResize";
import { sortEveryNth } from "../../utils/sortEveryNth";
import { ReactComponent as LongRightArrow } from "../../assets/images/icons/long-right-arrow.svg";
import Footer from "../commons/footer";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import SimpleReactLightbox from "simple-react-lightbox";
import { getAllUrlParams } from "../../utils/getAllUrlParams";

const GalleryWrapper = (props) => {
  return (
    <div {...props} className="gallery-wrapper cw-gallery-wrapper">
      {props.children}
    </div>
  );
};

const GalleryImage = (props) => {
  const [openLightbox, setOpenLightBox] = useState(false);
  const [lightboxImage, setLightboxImage] = useState();

  const handleImageOnclick = () => {
    setOpenLightBox(true);
    setLightboxImage(props.data.image_url);
  };

  return (
    <>
      <div
        className={`gallery-image-wrapper gallery-image-wrapper-${
          props.index + 1
        }`}
      >
        <Link onClick={handleImageOnclick} to="#">
          <div
            className="gallery-image"
            style={{
              background: `url('${props.data.image_url}')`,
            }}
          >
            {/* <p>{props.data.name}</p>
        <Link to={`/clients/${props.data.id}`}>
          <p className="very-bold">
            Read more &nbsp;
            <LongRightArrow />
          </p>
        </Link> */}
          </div>
        </Link>
      </div>
      {openLightbox && (
        <Lightbox
          medium={lightboxImage}
          large={lightboxImage}
          onClose={() => {
            setOpenLightBox(false);
            setLightboxImage();
          }}
          hideDownload
        />
      )}
    </>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ClientGallery = () => {
  const [width] = useWindowSize();
  const [readMore, setReadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cwData, setCWData] = useState([]);
  const [paginationLinks, setPaginationLinks] = useState({});
  const [sortedImageData, setSortedImageData] = useState([]);

  let { id } = useParams();
  const history = useHistory();

  const query = useQuery();
  const pageNumber = query.get("page") ?? 1;
  console.log("pageNumber", +pageNumber);

  useEffect(() => {
    loodGallery();
  }, []);

  useEffect(() => {
    if (width > 1366) {
      setSortedImageData(sortEveryNth(cwData, 5));
    } else if (width > 1024 && width <= 1366) {
      setSortedImageData(sortEveryNth(cwData, 3));
    } else if (width > 800 && width <= 1024) {
      setSortedImageData(sortEveryNth(cwData, 2));
    } else {
      setSortedImageData(cwData);
    }
  }, [width, cwData]);

  const loodGallery = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/client/gallery/${id}?page=${pageNumber}`
      );
      const publishedPosts = response.data?.data;
      setCWData(publishedPosts ?? []);
      setPaginationLinks(response.data?.links);
      setIsLoading(false);
    } catch (error) {
      alert(
        `An error occured while fetching community works - ${error.message}`
      );
      setIsLoading(false);
    }
  };

  const onRightButtonClick = () => {
    const getParams = getAllUrlParams(paginationLinks.next);
    history.push(`/client/gallery/${id}?page=${getParams.page ?? 1}`);
    window.location.reload();
  };

  const onLeftButtonClick = () => {
    if (!isEmpty(paginationLinks.prev)) {
      const getParams = getAllUrlParams(paginationLinks.prev);
      history.push(`/client/gallery/${id}?page=${getParams.page ?? 1}`);
      window.location.reload();
    }
  };

  const description = () => {
    const textToDisplay = `"When we strive to become better than we are, everything around us becomes better too." - Paulo Coelho`;

    // return !readMore ? truncate(textToDisplay, 230) : textToDisplay;
    return textToDisplay;
  };

  const createDescriptionMarkup = () => {
    return { __html: description() };
  };

  const readMoreText = !readMore ? "See more" : "See less";

  const handleOnReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <SimpleReactLightbox>
      <div className="app-container">
        <div className="page-wrapper">
          <div className="community-work-section">
            <div className="community-work-header-section">
              <h1 className="community-work-header-title">Client Gallery</h1>

              <div>
                <p
                  className="community-work-header-description"
                  dangerouslySetInnerHTML={createDescriptionMarkup()}
                ></p>
                {/* <a className="very-bold" href onClick={handleOnReadMore}>
                  {readMoreText}
                </a> */}
              </div>
            </div>
            {isLoading && (
              <Spinner animation="grow" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {!isEmpty(sortedImageData) && (
              <GalleryWrapper>
                {sortedImageData.map((data, index) => (
                  <GalleryImage key={data.id} data={data} index={index} />
                ))}
              </GalleryWrapper>
            )}
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        showRightArrow={paginationLinks?.next !== null}
        showLeftArrow={paginationLinks?.prev !== null}
        onRightButtonClick={onRightButtonClick}
        onLeftButtonClick={onLeftButtonClick}
        rightArrowClass="live-right-arrow"
        leftArrowClass="live-left-arrow"
      />
    </SimpleReactLightbox>
  );
};

export default ClientGallery;
