import React, { useCallback, useEffect, useRef, useState } from "react";
import "./community-works.scss";
import isEmpty from "lodash/isEmpty";
import { Link, useHistory } from "react-router-dom";
import { useWindowSize } from "../../utils/useWindowResize";
import { sortEveryNth } from "../../utils/sortEveryNth";
import { ReactComponent as LongRightArrow } from "../../assets/images/icons/long-right-arrow.svg";

import Gallery from "react-grid-gallery";
import Footer from "../commons/footer";
import grid1 from "../../assets/images/community-works/1.png";
import grid2 from "../../assets/images/community-works/2.png";
import grid3 from "../../assets/images/community-works/3.png";
import axios from "axios";
import { truncate } from "../../utils/truncateString";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

const photos = [
  {
    src: grid1,
    thumbnail:
      "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    isSelected: true,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: grid2,
    thumbnail:
      "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    tags: [
      { value: "Ocean", title: "Ocean" },
      { value: "People", title: "People" },
    ],
    caption: "Boats (Jeshu John - designerspics.com)",
  },

  {
    src: grid3,
    thumbnail:
      "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
];

const GalleryWrapper = (props) => {
  return (
    <div {...props} className="gallery-wrapper cw-gallery-wrapper">
      {props.children}
    </div>
  );
};

const GalleryImage = (props) => {
  return (
    <div
      className={`gallery-image-wrapper gallery-image-wrapper-${
        props.index + 1
      }`}
    >
      <div
        className="gallery-image"
        style={{
          background: `linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),url('${props.data.featured_image_url}')`,
        }}
      >
        <p>{props.data.title}</p>
        <Link to={`/community-works/${props.data.id}`}>
          <p className="very-bold">
            Read more &nbsp;
            <LongRightArrow />
          </p>
        </Link>
      </div>
    </div>
  );
};

const CommunityWorks = () => {
  const [width] = useWindowSize();
  const [readMore, setReadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cwData, setCWData] = useState([]);
  const [paginationLinks, setPaginationLinks] = useState({});
  const [imageData, setImageData] = useState(photos);
  const [sortedImageData, setSortedImageData] = useState([]);

  useEffect(() => {
    loadCommunityWorks();
  }, []);

  useEffect(() => {
    if (width > 1366) {
      setSortedImageData(sortEveryNth(cwData, 5));
    } else if (width > 1024 && width <= 1366) {
      setSortedImageData(sortEveryNth(cwData, 3));
    } else if (width > 800 && width <= 1024) {
      setSortedImageData(sortEveryNth(cwData, 2));
    } else {
      setSortedImageData(cwData);
    }
  }, [width, cwData]);

  const onRightButtonClick = () => {};

  const loadCommunityWorks = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/community-works`
      );
      const publishedPosts = response.data?.data.filter(
        (post) => post.is_published
      );
      setCWData(publishedPosts ?? []);
      setPaginationLinks(response.data?.links);
      setIsLoading(false);
    } catch (error) {
      alert(
        `An error occured while fetching community works - ${error.message}`
      );
      setIsLoading(false);
    }
  };

  const description = () => {
    const textToDisplay = `"Everybody can be great. Because anybody can serve. You don’t
    have to have a college degree to serve. You don’t have to make
    your subject and your verb agree to serve. You don’t have to
    know the second theory of thermodynamics in physics to serve.
    You only need a heart full of grace. A soul generated by love. –
    <span class='very-bold'>Martin Luther King, Jr.</span>"
    <br />
    At BDIC, we are happiest when everything we do makes a difference, enhances the lives of others and effects positive change in the world.`;

    return !readMore ? truncate(textToDisplay, 230) : textToDisplay;
  };

  const createDescriptionMarkup = () => {
    return { __html: description() };
  };

  const readMoreText = !readMore ? "See more" : "See less";

  const handleOnReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Community Works - BDIC</title>
        <meta
          name="description"
          content="Everybody can be great. Because anybody can serve. You don’t
    have to have a college degree to serve. You don’t have to make
    your subject and your verb agree to serve."
        />
      </Helmet>
      <div className="app-container">
        <div className="page-wrapper">
          <div className="community-work-section">
            <div className="community-work-header-section">
              <h1 className="community-work-header-title">Community Works</h1>

              <div>
                <p
                  className="community-work-header-description"
                  dangerouslySetInnerHTML={createDescriptionMarkup()}
                ></p>
                <a className="very-bold" href onClick={handleOnReadMore}>
                  {readMoreText}
                </a>
              </div>
            </div>
            {isLoading && (
              <Spinner animation="grow" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {!isEmpty(sortedImageData) && (
              <GalleryWrapper>
                {sortedImageData.map((data, index) => (
                  <GalleryImage key={data.id} data={data} index={index} />
                ))}
              </GalleryWrapper>
            )}
          </div>
        </div>
      </div>
      <Footer className="sticky" />
    </>
  );
};

export default CommunityWorks;
