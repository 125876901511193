import React, { useEffect } from "react";
import "./live.scss";
import grid1 from "../../assets/images/grid-gallery/1.png";
import Footer from "../commons/footer";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const LiveDetails = () => {
  const [currentSlide] = React.useState(0);
  const [post, setPost] = React.useState([]);

  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    loadBlog();
  }, []);

  const onLeftButtonClick = () => {
    history.push("/live");
  };

  const loadBlog = async () => {
    try {
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/posts/${id}`
      );
      setPost(response.data.data ?? []);
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
    }
  };

  const createDescriptionMarkup = () => {
    return { __html: post?.body };
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{post?.title ?? ""} - Live - BDIC</title>
        <meta
          name="description"
          content="See a selection of our latest news, events and projects from
          BDIC. Feel free to write back to us if you see many things that
          pique your interest, plus if you would like to be part of our
          works and events next time."
        />
      </Helmet>
      <div className="app-container">
        <div className="live-details-page-wrapper">
          <div>
            <div className="row">
              {post.featured_image_url && (
                <div className="col-lg-5">
                  <div
                    className="live-details-img"
                    style={{
                      backgroundImage: `url('${
                        post?.featured_image_url ?? ""
                      }')`,
                    }}
                  ></div>
                </div>
              )}
              <div className="col-lg-7">
                <div id="wrapper">
                  <div id="scroller">
                    <h2>{post?.title}</h2>
                    <p
                      className="live-detatils-text"
                      dangerouslySetInnerHTML={createDescriptionMarkup()}
                    >
                      {/* {carouselData[currentSlide].description} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        showLeftArrow
        onLeftButtonClick={onLeftButtonClick}
      />
    </>
  );
};

export default LiveDetails;
