import React from "react";
import "./partners.scss";
import { useHistory } from "react-router-dom";
import sliderImg2 from "../../assets/background-images/11.png";
import Footer from "../commons/footer";
import { Helmet } from "react-helmet";

const carouselData = [
  {
    image: sliderImg2,
  },
];

const Partners = () => {
  const [currentSlide] = React.useState(0);
  let history = useHistory();
  const onLeftButtonClick = () => {
    history.push("/approach");
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Partners - BDIC</title>
        <meta
          name="description"
          content="Apart from our genius, we delegate everything within our
          partner circles. So, it is easy to freely recommend and put
          you in direct contact with friends-of-the-house who do best
          what we don’t bother to attempt."
        />
      </Helmet>
      <div className="app-container">
        <div className="page-wrapper">
          <div id="carousel">
            <div className="row slider">
              <div className="col-lg-5">
                <div className="sliderTextContainer">
                  <h1 className="mb-3">Partners</h1>
                  <p className="very-bold mb-3">What We Delegate So Well</p>
                  <p className="mb-4 sliderTextContainerDecription">
                    Apart from our genius, we delegate everything within our
                    partner circles. So, it is easy to freely recommend and put
                    you in direct contact with friends-of-the-house who do best
                    what we don’t bother to attempt.
                  </p>
                  <p className="mb-2">
                    Our strategic partners deliver the following:
                  </p>
                  <p className="mb-2">
                    - Research.
                    <br />- Sales and Marketing.
                    <br />- Brand Identity Design.
                    <br />- Media Buying.
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="slideImg1 partner-slideImg1">
                  <img src={carouselData[currentSlide].image} alt="partners" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        showLeftArrow
        // showRightArrow
        onLeftButtonClick={onLeftButtonClick}
        // onRightButtonClick={onRightButtonClick}
      />
    </>
  );
};

export default Partners;
