import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { validateEmail } from "../../../utils/validateEmail";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { sortBy } from "../../../utils/sort";

export const PastExhibitions = ({ post }) => {
  return (
    <div id="wrapper">
      <div id="client-scroller" style={{ height: 400 }}>
        {sortBy(post, {
          prop: "year",
          desc: true,
        }).map((event) => {
          const createDescriptionMarkup = () => {
            return { __html: event?.description };
          };

          return event?.is_published ? (
            <div className="mb-3">
              <Row>
                <Col lg={2}>
                  <div
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: 50,
                      background: "white",
                      backgroundImage: `url('https://admin.bdicofficial.com/${
                        event?.featured_image_url ?? ""
                      }')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </Col>
                <Col lg={10}>
                  <p className="client-upcoming-event-date">{event?.year}</p>
                  <h6>{event?.name}</h6>
                  <p>{event?.location}</p>
                </Col>
              </Row>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};
