import React, { useEffect } from "react";
import "./live.scss";
import grid1 from "../../assets/images/grid-gallery/1.png";
import Footer from "../commons/footer";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const [currentSlide] = React.useState(0);
  const [post, setPost] = React.useState([]);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Privacy Policy - BDIC</title>
        <meta
          name="description"
          content="At BDIC, accessible at bdicofficial.com, one of our main
                        priorities is the privacy of our visitors. This Privacy
                        Policy document contains types of information that is
                        collected and recorded by BDIC and how we use it."
        />
      </Helmet>
      <div className="app-container">
        <div className="live-details-page-wrapper">
          <div>
            <div className="row">
              <div className="col-lg-3">
                <h1>Privacy Policy</h1>
              </div>
              <div className="col-lg-9">
                <div id="wrapper">
                  <div id="scroller">
                    <h2>{post?.title}</h2>
                    <div className="live-detatils-text">
                      <p>
                        At BDIC, accessible at bdicofficial.com, one of our main
                        priorities is the privacy of our visitors. This Privacy
                        Policy document contains types of information that is
                        collected and recorded by BDIC and how we use it.
                      </p>
                      <p>
                        If you have additional questions or require more
                        information about our Privacy Policy, do not hesitate to
                        contact us through email at policies@bdicofficial.com
                      </p>
                      <p>
                        This privacy policy applies only to our online
                        activities and is valid for visitors to our website with
                        regards the information that they share and/or collect
                        in BDIC. This policy is not applicable to any
                        information collected offline or via channels other than
                        this website.
                      </p>
                      <br />
                      <h3>Consent</h3>
                      <p>
                        By using our website, you hereby consent to our Privacy
                        Policy and agree to its terms.
                      </p>
                      <br />
                      <h3>Information we collect</h3>
                      <p>
                        The personal information that you are asked to provide,
                        and the reasons why you are asked to provide it, will be
                        made clear to you at the point we ask you to provide
                        your personal information.
                      </p>
                      <p>
                        If you contact us directly, we may receive additional
                        information about you such as your names, email address,
                        phone number, industry, office address, income range,
                        staff strengths, the contents of the message and/or
                        attachments you may send us, and any other information
                        you may choose to provide.
                      </p>
                      <p>
                        When you request an appointment, brochure, service,
                        career opportunity, make an inquiry, et al, we may ask
                        for your contact information, including items such as
                        name, company name, address, email address, and
                        telephone number.
                      </p>
                      <br />
                      <h3>How we use your information</h3>
                      <p>
                        We use the information we collect in various ways,
                        including to:
                      </p>
                      <ul>
                        <li>
                          <p>- Provide, operate, and maintain our website</p>
                        </li>
                        <li>
                          <p>- Improve, personalise, and expand our website</p>
                        </li>
                        <li>
                          <p>
                            - Understand and analyse how you use our website
                          </p>
                        </li>
                        <li>
                          <p>
                            - Develop new products, services, features, and
                            functionality
                          </p>
                        </li>
                        <li>
                          <p>
                            - Communicate with you, either directly or through
                            one of our partners, including for customer service,
                            to provide you with updates and other information
                            relating to the website, responding to your
                            enquiries, as well as marketing and promotional
                            purposes
                          </p>
                        </li>
                        <li>
                          <p>- Send you emails</p>
                        </li>
                        <li>
                          <p>- Find and prevent fraud</p>
                        </li>
                      </ul>
                      <br />
                      <h3>Log Files</h3>
                      <p>
                        BDIC follows a standard procedure of using log files.
                        These files log visitors when they visit websites. All
                        hosting companies do this and a part of hosting
                        services' analytics. The information collected by log
                        files include internet protocol (IP) addresses, browser
                        type, Internet Service Provider (ISP), date and time
                        stamp, referring/exit pages, and possibly the number of
                        clicks. These are not linked to any information that is
                        personally identifiable. The purpose of the information
                        is for analysing trends, administering the site,
                        tracking users' movement on the website, and gathering
                        demographic information.
                      </p>
                      <br />
                      <h3>Cookies and Web Beacons</h3>
                      <p>
                        Like any other website, BDIC uses ‘cookies'. These
                        cookies are used to store information including
                        visitors' preferences, and the pages on the website that
                        the visitor accessed or visited. The information is used
                        to optimize the users' experience by customizing our web
                        page content based on visitors' browser type and/or
                        other information.
                      </p>
                      <br />
                      <h3>DoubleClick DART Cookie</h3>
                      <p>
                        Google is one of a third-party vendor on our site. It
                        also uses cookies, known as DART cookies, to serve ads
                        to our site visitors based upon their visit to
                        www.bdicofficial.com and other sites on the internet.
                        However, visitors may choose to decline the use of DART
                        cookies by visiting the Google ad and content network
                        Privacy Policy at the following URL
                        <br />
                        <a
                          href="https://policies.google.com/technologies/ads"
                          target="_blank"
                          rel="noreferrer"
                        >
                          – https://policies.google.com/technologies/ads.
                        </a>
                      </p>
                      <br />
                      <h3>Third-Party Privacy Policies</h3>
                      <p>
                        BDIC's Privacy Policy does not apply to other
                        advertisers or websites. Thus, we are advising you to
                        consult the respective Privacy Policies of these
                        third-party servers for more detailed information. It
                        may include their practices and instructions about how
                        to opt-out of certain options.
                      </p>
                      <p>
                        You can choose to disable cookies through your
                        individual browser options. To know more detailed
                        information about cookie management with specific web
                        browsers, it can be found at the browsers' respective
                        websites. What Are Cookies?
                      </p>
                      <br />
                      <h3>
                        CCPA Privacy Policy (We Do Not Sell Personal
                        Information)
                      </h3>
                      <p>
                        Under the CCPA, among other rights, consumers have the
                        right to:
                      </p>
                      <p>
                        Request that a business that collects a consumer's
                        personal data disclose the categories and specific
                        pieces of personal data that a business has collected
                        about consumers.
                      </p>
                      <p>
                        Request that a business delete any personal data about
                        the consumer that a business has collected.
                      </p>
                      <p>
                        Request that a business that sells a consumer's personal
                        data, not sell the consumer's personal data.
                      </p>
                      <p>
                        If you make a request, we have one month to respond to
                        you. If you would like to exercise any of these rights,
                        please contact us.
                      </p>
                      <br />
                      <h3>GDPR Privacy Policy (Data Protection Rights)</h3>
                      <p>
                        We would like to make sure you are fully aware of all of
                        your data protection rights. Every user is entitled to
                        the following:
                      </p>
                      <p>
                        The right to access – You have the right to request
                        copies of your personal data. We may charge you a small
                        fee for this service
                      </p>
                      <p>
                        The right to rectification – You have the right to
                        request that we correct any information you believe is
                        inaccurate. You also have the right to request that we
                        complete the information you believe is incomplete.
                      </p>
                      <p>
                        The right to erasure – You have the right to request
                        that we erase your personal data, under certain
                        conditions.
                      </p>
                      <p>
                        The right to restrict processing – You have the right to
                        request that we restrict the processing of your personal
                        data, under certain conditions.
                      </p>
                      <p>
                        The right to object to processing – You have the right
                        to object to our processing of your personal data, under
                        certain conditions.
                      </p>
                      <p>
                        The right to data portability – You have the right to
                        request that we transfer the data that we have collected
                        to another organization, or directly to you, under
                        certain conditions.
                      </p>
                      <p>
                        If you make a request, we have one month to respond to
                        you. If you would like to exercise any of these rights,
                        please contact us.
                      </p>
                      <br />
                      <h3>Children's Information</h3>
                      <p>
                        Another part of our priority is adding protection for
                        children while using the internet. We encourage parents
                        and guardians to observe, participate in, and/or monitor
                        and guide their online activity.
                      </p>
                      <p>
                        BDIC does not knowingly collect any Personal
                        Identifiable Information from children under the age of
                        13. If you think that your child provided this kind of
                        information on our website, we strongly encourage you to
                        contact us immediately and we will do our best efforts
                        to promptly remove such information from our records.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="sticky" />
    </>
  );
};

export default PrivacyPolicy;
