import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { validateEmail } from "../../../utils/validateEmail";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { sortBy } from "../../../utils/sort";

export const UpcomingEvents = ({ post }) => {
  const getPost = () => {
    const now = Date.now();
    return post.filter((event) => {
      // Filter out dates in the past or falsey values
      return event?.date && new Date(event?.date).getTime() > now;
    });
  };
  return (
    <div id="wrapper">
      <div id="client-scroller" style={{ height: 400 }}>
        {sortBy(getPost(), {
          prop: "date",
          desc: false,
        }).map((event) => {
          const createDescriptionMarkup = () => {
            return { __html: event?.description };
          };

          return event?.is_published ? (
            <Row>
              <Col lg={2}>
                <div
                  style={{
                    height: 100,
                    width: 100,
                    borderRadius: 50,
                    background: "white",
                    backgroundImage: `url('https://admin.bdicofficial.com/${
                      event?.featured_image_url ?? ""
                    }')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              </Col>
              <Col lg={10}>
                <div>
                  <p className="client-upcoming-event-date">
                    {moment(event?.date).format("ddd, D MMM yyyy h:m")}
                  </p>
                  <h6>{event?.name}</h6>
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={createDescriptionMarkup()}
                  ></p>
                </div>
              </Col>
            </Row>
          ) : null;
        })}
        <div style={{ height: 100 }}></div>
      </div>
    </div>
  );
};
