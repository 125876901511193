import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./approach.scss";
import Footer from "../commons/footer";
import image1 from "../../assets/background-images/10.png";
import { Helmet } from "react-helmet";

const Approach = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Our Approach - BDIC</title>
        <meta name="description" content="How We Do, What We Do Too Well" />
      </Helmet>
      <div className="app-container approach-app-container">
        <div className="page-wrapper">
          <Container fluid>
            <Row>
              <Col md={12} lg={2} pull-right>
                <h1>Approach</h1>
              </Col>
              <Col md={12} lg={1}></Col>
              <Col md={12} lg={3} pull-right>
                <p className="very-bold">How We Do, What We Do Too Well</p>
                <p className="italics">
                  PS II: One-off listening fee paid at start will be deducted
                  from your invoice when You Engage Us.
                </p>
                <br />
                <p className="very-bold">Our Process</p>
                <p>
                  - We Charge You a one-off capped listening fee to hear all
                  about your problems.
                  <br />
                  - We Engage You in scheduled follow-up conversations to share
                  our insights into your problem areas and begin to flesh out
                  some directions for our solution.
                  <br />- We Send You our proposal, in some cases:
                </p>
              </Col>

              <Col md={12} lg={1}></Col>
              <Col md={12} lg={4} pull-right>
                <p className="mt-3 "></p>
                <p className="bold pl-5">
                  i. With deliverable(s)/service description, time and cost
                  implications.
                </p>
                <p className="bold pl-5 mb-3">
                  ii. With our scope of work, retainer fees and duration.
                </p>
                <p>- You Engage Us.</p>
                <p>- We Deliver.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="approach-edge-image">
        <img src={image1} alt="" />
      </div>
      <Footer className="sticky" />
    </>
  );
};

export default Approach;
