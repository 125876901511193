import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import "./join-the-team.scss";
import Footer from "../commons/footer";
import { Link } from "react-router-dom";
import { validateEmail } from "../../utils/validateEmail";
import AppSemiDark from "../AppSemiDark";
import axios from "axios";
import { animated } from "react-spring/renderprops";
import { Helmet } from "react-helmet";
import { useWindowSize } from "../../utils/useWindowResize";

const INITIAL_FORM_DATA = {
  firstname: "",
  lastname: "",
  email: "",
  who_are_you: "",
};

const JOINTHETEAM = (props) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [currentForm, setCurrentForm] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const [width] = useWindowSize();
  console.log("width", width);
  const onChangeText = (name) => (evt) => {
    setFormData({
      ...formData,
      [name]: evt.target.value,
    });
  };

  const requiredFieldsAreNotEmpty = (requiredInputs) => {
    let errorCount = 0;
    requiredInputs.forEach((input) => {
      if (input === "email") {
        if (!validateEmail(formData[input])) {
          setFormErrors((prev) => ({
            ...prev,
            [input]: `Email is not valid`,
          }));
          errorCount++;
        }
      }
      if (isEmpty(formData[input])) {
        setFormErrors((prev) => ({
          ...prev,
          [input]: `This field is required and cannot be empty.`,
        }));
        errorCount++;
      }
    });

    if (errorCount > 0) {
      return false;
    }

    errorCount = 0;
    setFormErrors({});
    return true;
  };

  const handleFormSubmitPerState = () => {
    const resetForm = () => {
      setFormData(INITIAL_FORM_DATA);
      setCurrentForm(0);
    };
    if (
      requiredFieldsAreNotEmpty([
        "firstname",
        "lastname",
        "email",
        "who_are_you",
      ])
    ) {
      setSubmitting(true);

      try {
        setSubmitting(true);
        // axios.post("/api/sendmail", formData);
        axios
          .post("/.netlify/functions/join-us", formData)
          .then((res) => {
            setSubmitting(false);
            if (res.data.result !== "success") {
              alert("Email failed to send. Please try again.");
              setTimeout(() => {
                resetForm();
              }, 6000);
            } else {
              alert("Thank you for placing a request to join us.");
              setTimeout(() => {
                resetForm();
              }, 6000);
            }
          })
          .catch((err) => {
            alert("Oops, an unexpected error occured. Please try again.");
            setSubmitting(false);
          });
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  const onSubmit = () => {};

  const renderForm = () => {
    switch (currentForm) {
      case 0:
        return (
          <div className="jt-landing-container">
            <div className="jt-landing-container-row">
              <div className="jt-landing-container-col">
                <div className="jt-title-container ">
                  <h1
                    className="primary-color-dark mb-2 jt-header-text"
                    style={{ textAlign: "left" }}
                  >
                    Join the Team
                  </h1>
                  <div style={{ fontSize: 14 }} className="pt-4 mb-3">
                    We are always excited to meet disruptive minds, welcome
                    onboard and work with exceptional A+++ talents at BDIC.
                    <br />
                    <br />
                    We choose our team as carefully as you’d expect one would
                    choose a partner – so really, apart from the creative
                    expectations, our doors swing open to that purposeful and
                    talented human being with a very healthy personality, an
                    open mind, impact trail and kind nature.
                    <br />
                    <br />
                    One single shot – please feel free to point us to your best
                    works and leave the rest of the process to us.
                    <br />
                    <br />
                    Fill in your details below, and we would send you an email
                    requesting your resume/portfolio and cover introduction.
                  </div>
                </div>
              </div>
              <div className="jt-landing-container-col">
                <div className="jt-form-wrapper">
                  <div className="form-container">
                    <p className="input-title">FIRST NAME:</p>
                    <div className="input-container">
                      <input
                        className="input jt-input"
                        onChange={onChangeText("firstname")}
                        value={formData.firstname}
                      />
                      {formErrors["firstname"] && (
                        <p className="form-error-text">
                          ${formErrors["firstname"]}
                        </p>
                      )}
                    </div>

                    <p className="input-title">LAST NAME:</p>
                    <div className="input-container">
                      <input
                        className="input jt-input"
                        onChange={onChangeText("lastname")}
                        value={formData.lastname}
                      />
                      {formErrors["lastname"] && (
                        <p className="form-error-text">
                          ${formErrors["lastname"]}
                        </p>
                      )}
                    </div>
                    <p className="input-title">EMAIL:</p>
                    <div className="input-container">
                      <input
                        className="input jt-input"
                        onChange={onChangeText("email")}
                        value={formData.email}
                      />
                      {formErrors["email"] && (
                        <p className="form-error-text">
                          ${formErrors["email"]}
                        </p>
                      )}
                    </div>
                    <p className="input-title">WHO ARE YOU:</p>
                    <div className="input-container">
                      <textarea
                        className="input jt-input"
                        rows="4"
                        cols="60"
                        name="text"
                        onChange={onChangeText("who_are_you")}
                        value={formData.who_are_you}
                      ></textarea>
                      {formErrors["who_are_you"] && (
                        <p className="form-error-text">
                          ${formErrors["who_are_you"]}
                        </p>
                      )}
                    </div>
                    <div
                      className="btn-wrapper jt-request-br-btn-wrapper"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <div
                        className="continue-btn jt-request-btn"
                        onClick={handleFormSubmitPerState}
                      >
                        <Link>
                          {submitting
                            ? "Requesting..."
                            : "Request Your Opportunity"}
                          &nbsp;
                          <svg
                            width="46"
                            height="15"
                            viewBox="0 0 46 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                              fill="black"
                            />
                            <path
                              d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                              fill="black"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <AppSemiDark style={props.style}>
      <Helmet>
        <html lang="en" />
        <title>Join The Team - BDIC</title>
        <meta
          name="description"
          content="We are always excited to meet disruptive minds, welcome
          onboard and work with exceptional A+++ talents at BDIC."
        />
      </Helmet>
      <animated.div className="app-container jt-app-container">
        <Container fluid>
          <form onSubmit={onSubmit}>{renderForm()}</form>
        </Container>
      </animated.div>
      <Footer className="sticky" />
    </AppSemiDark>
  );
};

export default JOINTHETEAM;
