import React from "react";
import "./principles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Footer from "../commons/footer";
import image1 from "../../assets/images/carousel/whoarewe - originals - dolamubadejo bdic 2.png";
import { Helmet } from "react-helmet";

const OurPromise = ({ history }) => {
  const onLeftButtonClick = () => {
    history.push("/about");
  };
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Our Promise - BDIC</title>
        <meta name="description" content="So here is our Promise. No Cap" />
      </Helmet>
      <div className="app-container">
        <div className="page-wrapper mt-8">
          <Container fluid>
            <Row>
              <Col md={12} lg={1} className="pt-0">
                <h3 className="our-promise-page-title">Principles</h3>
              </Col>
              <Col md={12} lg={3} style={{ width: "90%" }}>
                <p className="smallerText pt-1">Our Promise</p>
                <h1>So here is our Promise. No Cap </h1>
                <p className="smallerText">
                  - You will make a dent in the Luxury space and earn the
                  recognition your business deserves.
                </p>
                <p className="smallerText">
                  - You will attract and retain your ideal target customers and
                  be positioned right in the middle of the coolest opportunities
                  to up your game.
                </p>
                <p className="smallerText">
                  - You will deepen your impact, multiply profit, earn
                  additional forms of wealth and scale your business; possibly
                  without unnecessary VC funding or giving away half of your
                  business equity and dream as collateral. What we are saying
                  essentially here is, the ball will always be in your court.
                </p>
                <p className="smallerText">
                  - Your brand (and product) will never be forgotten in such a
                  hurry.
                </p>
              </Col>
              <Col md={12} lg={8} className="pr-0">
                <div className="our-promise-image">
                  <img src={image1} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer
        // className="sticky"
        showLeftArrow
        reverse
        onLeftButtonClick={onLeftButtonClick}
      />
    </>
  );
};

export default withRouter(OurPromise);
