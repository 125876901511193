import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { animated } from "react-spring/renderprops";
import "./services.scss";
import Footer from "../commons/footer";
import GoldPackageImg from "../../assets/images/packages/gold-packages.png";
import SilverPackageImg from "../../assets/images/packages/silver-packages.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const WhatWeDoWell = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>What We Do Too Well - BDIC</title>
        <meta
          name="description"
          content="PS: Please note, because each client presents unique
          perspectives to their challenges, and no experience of any
          problem is the same, we do not expatiate on our standard
          service descriptions here but rely primarily on our thorough
          pre-assessments, listening and follow up consultations to
          develop specific problem-solving deliverables for our clients."
        />
      </Helmet>
      <animated.div className="app-container">
        <div className="page-wrapper">
          <Container fluid>
            <Row>
              <Col md={12} lg={1} pull-right></Col>
              <Col md={12} lg={3} pull-right>
                <h1>What We Do Too Well</h1>
              </Col>
              <Col md={12} lg={5}>
                <p className="what-we-do-well-paragraph">
                  PS: Please note, because each client presents unique
                  perspectives to their challenges, and no experience of any
                  problem is the same, we do not expatiate on our standard
                  service descriptions here but rely primarily on our thorough
                  pre-assessments, listening and follow up consultations to
                  develop specific problem-solving deliverables for our clients.
                </p>
                <div className="mt-6">
                  <Row>
                    <Col lg={6} className="no-padding-left mb-5">
                      <Link to="/services/what-we-do-well/gold-service">
                        <p className="very-bold packages mb-2">
                          GOLD SERVICE &nbsp;
                          <svg
                            width="14"
                            height="11"
                            viewBox="0 0 14 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.49276 10.4357L8.09692 10.0092L12.8752 5.20362L8.09692 0.398091L8.49276 0L13.6669 5.20362L8.49276 10.4357Z"
                              fill="black"
                            />
                            <path
                              d="M13 4.91919H0V5.48789H13V4.91919Z"
                              fill="black"
                            />
                          </svg>
                        </p>
                        <img src={GoldPackageImg} alt="gold package" />
                      </Link>
                    </Col>
                    <Col lg={6} className="no-padding-left mb-5">
                      <Link to="/services/what-we-do-well/platinum-service">
                        <p className="very-bold packages mb-2">
                          PLATINUM SERVICE &nbsp;
                          <svg
                            width="14"
                            height="11"
                            viewBox="0 0 14 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.49276 10.4357L8.09692 10.0092L12.8752 5.20362L8.09692 0.398091L8.49276 0L13.6669 5.20362L8.49276 10.4357Z"
                              fill="black"
                            />
                            <path
                              d="M13 4.91919H0V5.48789H13V4.91919Z"
                              fill="black"
                            />
                          </svg>
                        </p>
                        <img src={SilverPackageImg} alt="silver package" />
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </animated.div>
      <Footer className="sticky" />
    </>
  );
};

export default WhatWeDoWell;
