import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import XML from "./sitemap.xml";
import { Transition } from "react-spring/renderprops";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { config } from "react-spring";
import { OurPromise, OurPhilosophy } from "./components/Principles";
import Landing from "./components/Landing";
import About from "./components/About";
import Layout from "./components/App";
import Home from "./components/Home";
import WhatWeDoWell from "./components/Services/what-we-do-well";
import Appointments from "./components/Contacts/appointments";
import GoldPackage from "./components/Services/gold-package";
import PlatinumPackage from "./components/Services/platinum-services";

import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Approach from "./components/Approach";
import Partners from "./components/Partners/partners";
import RequestBrochure from "./components/Contacts/request-brochure";
import FAQ from "./components/Contacts/faq";
import JOINTHETEAM from "./components/JoinTheTeam";
import Training from "./components/Training/training";
import SocialMedia from "./components/SocialMedia";
import TrainingDetails from "./components/Training/training-full-page";
import Live from "./components/Live/live";
import LiveDetails from "./components/Live/live-details";
import CommunityWorks from "./components/CommunityWorks/community-works";
import CommunityWorksDetails from "./components/CommunityWorks/community-works-details";
import PrivacyPolicy from "./components/PrivacyPolicy/privcay-policy";
import CookiePolicy from "./components/CookiePolicy/cookie-policy";
import ClientDetails from "./components/Clients/client-details";
import Clients from "./components/Clients";
import RequestClientMoreDetails from "./components/Clients/request-client-more-details";
import ClientGallery from "./components/Clients/client-details-gallery";
import ClientDetailsMore from "./components/Clients/client-details-more";
import ClientFullInfo from "./components/Clients/client-full-information";
import Onboarding139thAvenue from "./components/139thAvenueOnboarding";

const AnimatedRouter = () => (
  <BrowserRouter basename={"/"}>
    <Route
      render={({ location, ...rest }) => {
        return (
          <Transition
            native
            items={location}
            keys={location.pathname.split("/")[1]}
            from={{
              opacity: 0,
              transform: "translate3d(100%,0,0)",
              position: "absolute",
            }}
            enter={{
              opacity: 1,
              transform: "translate3d(0%,0,0)",
              position: "relative",
            }}
            leave={{
              opacity: 0,
              transform: "translate3d(-50%,0,0)",
              position: "absolute",
            }}
            config={{ ...config.molasses, duration: 750 }}
          >
            {(loc, state) => (style) => (
              <Switch location={state === "update" ? location : loc}>
                <Route exact path={`/`}>
                  <Landing style={style} />
                </Route>
                <Route exact path={`/appointments`}>
                  <Appointments style={style} />
                </Route>
                <Route exact path={`/request-brochure`}>
                  <RequestBrochure style={style} />
                </Route>
                <Route exact path={`/faq`}>
                  <FAQ style={style} />
                </Route>
                <Route exact path={`/join-the-team`}>
                  <JOINTHETEAM style={style} />
                </Route>

                <Layout style={style}>
                  <Route exact path={`/home`}>
                    <Home />
                  </Route>
                  <Route exact path={`/about`}>
                    <About />
                  </Route>
                  <Route exact path={`/our-philosophy`}>
                    <OurPhilosophy />
                  </Route>
                  <Route exact path={`/our-promise`}>
                    <OurPromise />
                  </Route>
                  <Route exact path={`/what-we-do-well`}>
                    <WhatWeDoWell />
                  </Route>
                  <Route exact path={`/139th-avenue-onboarding`}>
                    <Onboarding139thAvenue />
                  </Route>
                  <Route exact path={`/services/what-we-do-well/gold-service`}>
                    <GoldPackage />
                  </Route>
                  <Route
                    exact
                    path={`/services/what-we-do-well/platinum-service`}
                  >
                    <PlatinumPackage />
                  </Route>
                  <Route exact path={`/approach`}>
                    <Approach />
                  </Route>
                  <Route exact path={`/partners`}>
                    <Partners />
                  </Route>
                  <Route exact path={`/training`}>
                    <Training />
                  </Route>
                  <Route exact path={`/training/:id`}>
                    <TrainingDetails />
                  </Route>
                  <Route exact path={`/live`}>
                    <Live />
                  </Route>
                  <Route exact path={`/live/:id`}>
                    <LiveDetails />
                  </Route>
                  <Route exact path={`/community-works`}>
                    <CommunityWorks />
                  </Route>
                  <Route exact path={`/community-works/:id`}>
                    <CommunityWorksDetails />
                  </Route>
                  {/* <Route exact path={`/clients`}>
                    <Clients />
                  </Route> */}
                  <Route exact path={`/clients/:id`}>
                    <ClientDetails />
                  </Route>
                  <Route exact path={`/clients/:id/:uuid`}>
                    <ClientDetailsMore />
                  </Route>
                  <Route exact path={`/client/gallery/:id`}>
                    <ClientGallery />
                  </Route>
                  <Route exact path={`/client/request-more/:id`}>
                    <RequestClientMoreDetails />
                  </Route>
                  {/* <Route exact path={`/client/:id/full-info`}>
                    <ClientFullInfo />
                  </Route> */}
                  <Route exact path={`/clients/:id/:uuid/full-info`}>
                    <ClientFullInfo />
                  </Route>
                  <Route exact path={`/social-media`}>
                    <SocialMedia />
                  </Route>
                  <Route exact path={`/privacy-policy`}>
                    <PrivacyPolicy />
                  </Route>
                  <Route exact path={`/cookie-policy`}>
                    <CookiePolicy />
                  </Route>
                </Layout>
                <Route exact path={"/sitemap"}>
                  <XML />
                </Route>
              </Switch>
            )}
          </Transition>
        );
      }}
    />
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <html lang="en" />
      <title>
        BDIC - The positioning experts for emerging luxury brands in highly
        competitive markets. Also known as - Blonde Dolly Image Consulting,
        Blonde Dolly IC
      </title>
      <meta
        name="description"
        content="The positioning experts for emerging luxury brands in highly competitive markets. Also known as - Blonde Dolly Image Consulting, Blonde Dolly IC, BDIC"
      ></meta>
      <meta
        property="og:title"
        content="BDIC - The positioning experts for emerging luxury brands in highly competitive markets."
      ></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:url" content="www.bdicofficial.com"></meta>
      <meta
        property="og:description"
        content="The positioning experts for emerging luxury brands in highly competitive markets. Also known as - Blonde Dolly Image Consulting, Blonde Dolly IC, BDIC"
      ></meta>
      <meta property="og:site_name" content="BDIC"></meta>
      <meta
        property="og:image"
        content="https://www.bdicofficial.com/11.png"
      ></meta>
      <meta property="og:image:type" content="image/png"></meta>
      <meta property="og:image:width" content="1200"></meta>
      <meta property="og:image:height" content="627"></meta>
      <meta property="og:image:alt" content="BDIC"></meta>
      <meta name="twitter:card" content="summary"></meta>
      <meta name="twitter:site" content="@bdic_official"></meta>
      <meta name="twitter:creator" content="@bdic_official"></meta>
      <meta name="twitter:title" content="BDIC"></meta>
      <meta
        name="twitter:description"
        content="The positioning experts for emerging luxury brands in highly competitive markets."
      />
      <meta
        name="twitter:image"
        content="https://www.bdicofficial.com/11.png"
      ></meta>
    </Helmet>
    <AnimatedRouter />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
