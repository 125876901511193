import React from "react";
import "./training.scss";
// import sliderImg1 from "../../assets/images/carousel/positioning positioning 1 - faces - originals - dolamubadejo bdic 2.png";
import grid1 from "../../assets/images/grid-gallery/1.png";
// import sliderImg3 from "../../assets/images/carousel/ifwebreaktheice - originals - dolamubadejo bdic 1.png";
// import { ReactComponent as LongRightArrow } from "../../assets/images/icons/right-arrow.svg";
// import { ReactComponent as LongLeftArrow } from "../../assets/images/icons/left-arrow.svg";
import Footer from "../commons/footer";
import { useParams } from "react-router-dom";


const TrainingDetails = () => {
  const [currentSlide] = React.useState(0);

  let { id } = useParams();

  const carouselData = [
    {
      title: `There are many more exciting ways up a mountain...so why do you wanna bee like Gucccy? Crazy Hermit.`,
      description: `Our philosophy is simple. Don’t spend the money you {don’t} have to produce an imitation
      trying to impress customers who already bagged their PhD in sniffing out copycat brands
      from several miles. They don’t subscribe. Besides, there are so many more effective
      strategies we can deploy to help your business occupy a more honourable distinct position.
      Don’t you want that?`,
      image: grid1,
    },
  ];

  const createDescriptionMarkup = () => {
    return { __html: carouselData[currentSlide].description };
  };

  return (
    <>
      <div className="app-container">
        <div className="page-wrapper">
          <div id="carousel">
            <div className="row slider">
              <div className="col-lg-6">
                <div className="slideImg1">
                  <img
                    src={carouselData[currentSlide].image}
                    alt={carouselData[currentSlide].title}
                  />
                </div>
              </div>
              <div className="col-lg-6 pull-left">
                <div className="sliderTextContainer">
                  <h2>{carouselData[currentSlide].title}</h2>
                  <p dangerouslySetInnerHTML={createDescriptionMarkup()}>
                    {/* {carouselData[currentSlide].description} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        // showLeftArrow={showLeftArrow}
        // showRightArrow
        // onLeftButtonClick={onLeftButtonClick}
        // onRightButtonClick={onRightButtonClick}
      />
    </>
  );
};

export default TrainingDetails;
