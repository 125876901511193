import React, { useState, useEffect } from "react";
import { ReactComponent as TwitterIcon } from "../../../assets/images/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/images/icons/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/images/icons/instagram.svg";
import { Timeline } from "react-twitter-widgets";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as LongRightArrow } from "../../../assets/images/icons/right-arrow.svg";
import { ReactComponent as LongLeftArrow } from "../../../assets/images/icons/left-arrow.svg";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../../utils/useWindowResize";

const Footer = (props) => {
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [width] = useWindowSize();

  const handleShowSocialMedia = (show) => () => {
    if (width > 800) {
      setShowSocialMedia(show);
    }
  };

  const getYearPeriod = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year === 2021 ? "2021" : `2021 - ${year}`;
  };

  useEffect(() => {
    width !== 0 && width < 800 && setShowSocialMedia(true);
  }, [width]);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (width > 800) {
        setShowSocialMedia(false);
      }
    }, 7000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [showSocialMedia]);

  return (
    <footer
      {...props}
      className={`${props.className} ${props.reverse && "with-reverse"}`}
    >
      <div className={`footer-container ${props.reverse && "reverse"}`}>
        <ul className="footer-menu">
          <li className="footer-menu-item">
            <div>Social</div>
            <ul className="footer-menu-item-dropdown">
              <li>
                <div>
                  <Link to="/live">Live</Link>
                </div>
              </li>
              <li>
                <div>
                  <Link onClick={handleShowSocialMedia(true)}>
                    Social Media
                  </Link>
                  {showSocialMedia && (
                    <div
                      className="footer-menu-item-dropdown-mega-menu"
                      onClick={handleShowSocialMedia(false)}
                    >
                      <div className="row">
                        <div className="row-item col-md-4">
                          <div className="social-icon">
                            <TwitterIcon />
                          </div>
                          <p className="row-item-title">Twitter</p>
                          <p
                            className="row-item-subtitle social-media-text twitter-text"
                            style={{ marginBottom: 0 }}
                          >
                            <a
                              href="https://twitter.com/bdic_official"
                              target="_blank"
                              rel="noreferrer"
                            >
                              @bdic_official
                            </a>
                            <br />
                            <a
                              href="https://twitter.com/binf_community"
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginBottom: 0 }}
                            >
                              @binf_community
                            </a>
                          </p>
                        </div>
                        <div className="row-item col-md-4">
                          <a
                            href="https://www.instagram.com/bdic_official/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="social-icon">
                              <InstagramIcon width={14} height={11} />
                            </div>
                            <p className="row-item-title">Instagram</p>
                            <p className="row-item-subtitle social-media-text">
                              @bdic_official
                            </p>
                          </a>
                        </div>
                        <div className="row-item col-md-4">
                          <a
                            href="https://www.youtube.com/channel/UChLiXfy3KiKaYdPcuEgRPPw/featured"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="social-icon">
                              <YoutubeIcon width={14} height={11} />
                            </div>
                            <p className="row-item-title">YouTube</p>
                            <p className="row-item-subtitle">@BDICTube</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </li>
          <li className="footer-menu-item">
            <div>Culture</div>
            <ul className="footer-menu-item-dropdown">
              <li>
                <div>
                  <Link to="/join-the-team" style={{ textTransform: "none"}}>Join the Team</Link>
                </div>
              </li>
              {/* <li>
                <div>
                  <Link to="/training">Training</Link>
                </div>
              </li> */}
              <li>
                <div>
                  <Link to="/community-works">Binf Community</Link>
                </div>
              </li>
              <li>
                <div>
                  <Link to="/139th-avenue-onboarding">139th Avenue Onboarding</Link>
                </div>
              </li>
            </ul>
          </li>
          <li className="footer-menu-item">
            <div>Policy</div>
            <ul className="footer-menu-item-dropdown">
              <li>
                <div>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
              </li>
              <li>
                <div>
                  <Link to="/cookie-policy">Cookie Policy</Link>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <div className="arrow-container">
          <div className="row">
            {props.showLeftArrow && (
              <div
                className={`col-md-6 col-lg-6`}
                style={{ cursor: "pointer", paddingLeft: 0 }}
                onClick={props.onLeftButtonClick}
              >
                <div className={`${props.leftArrowClass ?? ""}`}>
                  {isEmpty(props.leftArrowClass) && <LongLeftArrow />}
                </div>
              </div>
            )}
            {props.showRightArrow && (
              <div
                className={`col-md-6 col-lg-6 pull-right`}
                onClick={props.onRightButtonClick}
                style={{ cursor: "pointer" }}
              >
                <div className={`${props.rightArrowClass ?? ""}`}>
                  {isEmpty(props.rightArrowClass) && <LongRightArrow />}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <p className={`copyright ${props.reverse && "copyright-reverse"}`}>
        © {getYearPeriod()} BLONDEDOLLY IC
      </p>
    </footer>
  );
};

export default Footer;
