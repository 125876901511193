import React, { useEffect } from "react";
import { animated } from "react-spring/renderprops";
import sliderImg1 from "../../assets/images/carousel/positioning positioning 1 - faces - originals - dolamubadejo bdic 2.png";
import sliderImg2 from "../../assets/images/carousel/beeelikegucccy- originals - dolamubadejo bdic  1.png";
import sliderImg3 from "../../assets/images/carousel/ifwebreaktheice - originals - dolamubadejo bdic 1.png";
import sliderImg4 from "../../assets/images/carousel/beeelikegucccy- originals - dolamubadejo-2 bdic  1.png";
import Footer from "../commons/footer";
import "./home.scss";
import { Helmet } from "react-helmet";

const carouselData = [
  {
    title: `<div style="width: 90%";>Positioning. Positioning. Positioning...<br>eff it! okay, maybe penetration. Just once. <br></div>`,
    description: `Our agency creates and execute sustainable positioning strategies for emerging luxury brands in fashion, accessories, arts, fragrance, hospitality and recreation. You choose the market. We enter it. <br>You don’t have a market? Not a problem; we create one.`,
    image: sliderImg1,
    alt: "Positioning. Positioning. Positioning...",
  },
  {
    title: `There is more to life than a costly photoshoot. We care about your message. But most, how you tell it.`,
    description: `There is a science behind the art of storytelling. It is called
    Semiotics. And nobody gives meaning to a brand and even product,
    better than us. Our visual communication and media planning
    ability are by themselves prized assets. You can cash it anytime
    for a large stake in the Luxury space. And this is easily our
    forte.`,
    image: sliderImg2,
    alt: "There is more to life than a costly photoshoot.",
  },
  {
    title: `<div style="width: 85%";>If we break the ice, how do we sustain the hype? We simply
    don’t. We’d rather create an irreparable dent first... it stays
    noticeable.</div>`,
    description: `<div style="width: 90%";>Impact over income equals relevance - do the math. We 
    want you in ease, still weighty and not burning
    out from the exhaustion that comes with ‘keeping up <strike>with the Kardashians’</strike>… especially
    when we no longer work on your brand. Hence, our approach to image development and
    strategy deployment remain organic yet well fertilised for your lifetime gains. Ask around,
    we are not blonde for nothing.</div>`,
    image: sliderImg3,
    alt: "If we break the ice, how do we sustain the hype?",
  },
  {
    title: `<div style="width: 90%";>There are many more exciting ways up a mountain...so why do you wanna bee like Gucccy? Crazy Hermit.
    </div>`,
    description: `<div style="width: 88%";>Our philosophy is simple. Don’t spend the money you {don’t} have to produce an imitation
    trying to impress customers who already bagged their PhD in sniffing out copycat brands
    from several miles. They don’t subscribe. Besides, there are so many more effective
    strategies we can deploy to help your business occupy a more honourable distinct position.
    Don’t you want that?</div>`,
    image: sliderImg4,
    alt: "There are many more exciting ways up a mountain...",
  },
];

const Home = ({ style }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [showRightArrow, setShowRightArrow] = React.useState(true);
  const [showLeftArrow, setShowLeftArrow] = React.useState(true);

  React.useEffect(() => {
    if (currentSlide === carouselData.length - 1) {
      setShowRightArrow(false);
    }

    if (currentSlide === 0) {
      setShowLeftArrow(false);
    }

    setShowRightArrow(true);
    setShowLeftArrow(true);
  }, [currentSlide]);

  useEffect(() => {
    const loop = setInterval(() => {
      if (currentSlide === carouselData.length - 1) {
        setCurrentSlide(0);
      } else {
        setCurrentSlide(currentSlide + 1);
      }
    }, 15000);
    return () => clearInterval(loop);
  }, [currentSlide]);

  const onLeftButtonClick = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide(carouselData.length - 1);
    }
  };

  const onRightButtonClick = () => {
    const nextSlide = currentSlide + 1;
    if (nextSlide >= carouselData.length) {
      setCurrentSlide(0);
      return;
    }
    setCurrentSlide(nextSlide);
  };

  const createDescriptionMarkup = () => {
    return { __html: carouselData[currentSlide].description };
  };
  const createTitleMarkup = () => {
    return { __html: carouselData[currentSlide].title };
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Home - BDIC</title>
        <meta
          name="description"
          content="Our agency creates and execute sustainable positioning strategies for emerging luxury brands in fashion, accessories, arts, fragrance, hospitality and recreation. You choose the market. We enter it."
        />
      </Helmet>
      <animated.div className="app-container">
        <div className="page-wrapper">
          <div id="carousel">
            <div className="row slider">
              <div className="col-lg-5">
                <div className="sliderTextContainer">
                  <h3 dangerouslySetInnerHTML={createTitleMarkup()}></h3>
                  <p dangerouslySetInnerHTML={createDescriptionMarkup()}></p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="slideImg">
                  <img
                    src={carouselData[currentSlide].image}
                    alt={carouselData[currentSlide].alt}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="slider-controls">
        <div className="row float-lg-right">
          <div className="col-md-6 ">
            <div className="left" onClick={onLeftButtonClick}>
              <LongLeftArrow width="88" height="29" />
            </div>
          </div>
          {showLeftArrow && (
            <div className="col-md-6 ">
              <div className="right" onClick={onRightButtonClick}>
                <LongRightArrow width="88" height="29" strokeWidth="1px" />
              </div>
            </div>
          )}
        </div>
      </div> */}
        </div>
      </animated.div>
      <Footer
        className="sticky"
        showLeftArrow={showLeftArrow}
        showRightArrow={showRightArrow}
        onLeftButtonClick={onLeftButtonClick}
        onRightButtonClick={onRightButtonClick}
      />
    </>
  );
};

export default Home;
