import React, { useEffect } from "react";
import "../Live/live.scss";
import "./clients.scss";
import grid1 from "../../assets/images/grid-gallery/1.png";
import Footer from "../commons/footer";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";

const ClientDetails = () => {
  const [currentSlide] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [post, setPost] = React.useState({});

  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    loadClientInfo();
  }, []);

  const onRightArrowClick = () => {
    post.uuid && history.push(`/clients/${id}/${post.uuid}`);
  };

  const loadClientInfo = async () => {
    try {
      const response = await axios.get(
        `https://admin.bdicofficial.com/api/clients/${id}`
      );
      setPost(response.data ?? {});
    } catch (error) {
      alert(`An error occured while fetching posts - ${error.message}`);
    }
  };

  const handleShowFullImage = () => {
    setShowModal(!showModal);
  };

  const createDescriptionMarkup = () => {
    return { __html: post?.description };
  };

  return (
    <>
      <div className="app-container">
        <div className="live-details-page-wrapper">
          <div>
            <div className="row">
              {post.client_image_url && (
                <div className="col-lg-5">
                  <div
                    className="client-details-img"
                    style={{
                      backgroundImage: `url('https://admin.bdicofficial.com/${
                        post?.client_image_url ?? ""
                      }')`,
                    }}
                    onClick={handleShowFullImage}
                  ></div>
                </div>
              )}
              <div className="col-lg-7">
                <div id="wrapper">
                  <div id="client-scroller">
                    <h2>{post?.name}</h2>
                    <p
                      className="live-detatils-text"
                      dangerouslySetInnerHTML={createDescriptionMarkup()}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {post.uuid && (
                    <Link
                      to={`/clients/${id}/${post.uuid}`}
                      style={{ textAlign: "center" }}
                      className="very-bold"
                    >
                      VIEW MORE{" "}
                      <svg
                        width="31"
                        height="10"
                        viewBox="0 0 31 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.9349 10L25.5474 9.59128L30.225 4.98638L25.5474 0.381471L25.9349 0L31 4.98638L25.9349 10Z"
                          fill="black"
                        />
                        <path
                          d="M30.6125 4.71387H0V5.25883H30.6125V4.71387Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleShowFullImage}
        backdrop="static"
        contentClassName="client-modal-img"
      >
        <Modal.Header
          closeButton
          className="client-details-modal-img-header"
        ></Modal.Header>
        <Modal.Body className="client-details-modal-img-body">
          <div
            className="client-details-img client-details-modal-img"
            style={{
              backgroundImage: `url('https://admin.bdicofficial.com/${
                post?.client_image_url ?? ""
              }')`,
            }}
            onClick={handleShowFullImage}
          ></div>
          {/* <div className="client-details-modal-img-description">
            <p>AWUNRIKA (Happiness is Supreme)</p>
            <p>Oil and acrylic on canvas 72x54 inches</p>
          </div> */}
        </Modal.Body>
      </Modal>
      <Footer
        className="sticky"
        showRightArrow
        onRightButtonClick={onRightArrowClick}
      />
    </>
  );
};

export default ClientDetails;
