import React from "react";
import { withRouter } from "react-router-dom";
import "./principles.scss";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../commons/footer";
import image1 from "../../assets/background-images/BDIC blong dolly Brochure11 1.png";
import { Helmet } from "react-helmet";

const OurPhilosophy = ({ history, style }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Our Philosophy - BDIC</title>
        <meta
          name="description"
          content="This is the theory or attitude that acts as our guiding
          principle for our behavior and all the works that we do.
          Not too surprisingly, it also coincides with our initials."
        />
      </Helmet>
      <div className="app-container">
        <div className="page-wrapper">
          <Container fluid className="philosphy-container">
            <Row>
              <Col md={12} lg={5} className="pt-0">
                <Row>
                  <Col md={12} lg={12} className="pt-0">
                    <h3 className="principles-page-title">
                      Our <br />
                      Philosophy
                    </h3>
                    <p className="principle-text-align-left">
                      This is the theory or attitude that acts as our guiding
                      principle for our behavior and all the works that we do.
                      Not too surprisingly, it also coincides with our initials.
                    </p>
                  </Col>
                </Row>
                <h1 className="principles-text-footer">
                  Build. Dominate. <br />
                  In Clover
                </h1>
              </Col>
              <Col md={12} lg={1} className="row-margin"></Col>
              <Col md={12} lg={5}>
                <Row>
                  <Col md={12} lg={12} className="pt-0">
                    <h3 className="principles-page-title principle-page-title2">
                      Our Values
                    </h3>
                    <p className="principle-text-align-left principle-text-align-left-2">
                      We do everything in house and within our tight circles. We
                      don’t borrow. We won’t even buy - and that includes all
                      our images and illustrations, which by the way are our
                      originals.
                    </p>
                  </Col>
                </Row>
                <h1 className="principles-text-footer principles-text-footer-2">
                  Autonomy. Disruption. <br />
                  Superior Artistry
                </h1>
              </Col>
            </Row>
          </Container>
          <div className="principles-edge-image">
            <img src={image1} alt="" />
          </div>
        </div>
      </div>
      <Footer
        className="sticky"
        // showRightArrow
        // onRightButtonClick={onRightButtonClick}
      />
    </>
  );
};

export default withRouter(OurPhilosophy);
