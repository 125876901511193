import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import "./contacts.scss";
import Footer from "../commons/footer";
import { Link } from "react-router-dom";
import AppDark from "../AppDark";
import { validateEmail } from "../../utils/validateEmail";
import { animated } from "react-spring";
import { Helmet } from "react-helmet";

const INITIAL_FORM_DATA = {
  firstname: "",
  lastname: "",
  email: "",
  company: "",
  industry: "",
};
const RequestBrochure = (props) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [currentForm, setCurrentForm] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onChangeText = (name) => (evt) => {
    setFormData({
      ...formData,
      [name]: evt.target.value,
    });
    if (isEmpty(evt.target.value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: `This field is required and cannot be empty.`,
      }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const requiredFieldsAreNotEmpty = (requiredInputs) => {
    let errorCount = 0;
    requiredInputs.forEach((input) => {
      if (input === "email") {
        if (!validateEmail(formData[input])) {
          setFormErrors((prev) => ({
            ...prev,
            [input]: `Email is not valid`,
          }));
          errorCount++;
        }
      }
      if (isEmpty(formData[input])) {
        setFormErrors((prev) => ({
          ...prev,
          [input]: `This field is required and cannot be empty.`,
        }));
        errorCount++;
      }
    });

    if (errorCount > 0) {
      return false;
    }

    errorCount = 0;
    setFormErrors({});
    return true;
  };

  const handleFormSubmitPerState = () => {
    const resetForm = () => {
      setFormData(INITIAL_FORM_DATA);
      setCurrentForm(0);
    };
    if (
      requiredFieldsAreNotEmpty([
        "firstname",
        "lastname",
        "company",
        "email",
        "industry",
      ])
    ) {
      setSubmitting(true);

      try {
        setSubmitting(true);
        // axios.post("/api/sendmail", formData);
        axios
          .post("/.netlify/functions/request-brochure", formData)
          .then((res) => {
            setSubmitting(false);
            if (res.data.result !== "success") {
              alert("Email failed to send. Please try again.");
              setTimeout(() => {
                resetForm();
              }, 6000);
            } else {
              alert(
                "Thank you for requesting the ebrochure. Please check your email in 1-2 business days."
              );
              setTimeout(() => {
                resetForm();
              }, 6000);
            }
          })
          .catch((err) => {
            alert("Oops, an unexpected error occured. Please try again.");
            setSubmitting(false);
          });
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  const onSubmit = () => {};

  const renderForm = () => {
    switch (currentForm) {
      case 0:
        return (
          <div className="rb-landing-container">
            <Row>
              <Col md={12} lg={5} pull-right>
                <div className="appointment-title-container">
                  <h1
                    className="primary-color-dark pt-0 pb-1 mb-0"
                    style={{ textAlign: "left" }}
                  >
                    Request Brochure
                  </h1>
                  <p
                    className="primary-color-dark pt-1 mb-3"
                    style={{ textAlign: "left" }}
                  >
                    Get the BDIC e-brochure
                  </p>
                </div>
              </Col>
              <Col md={12} lg={6} pull-right>
                <div className="rb-form-wrapper">
                  <div className="form-container">
                    <p className="input-title">FIRST NAME:</p>
                    <div className="input-container">
                      <input
                        className="input contact-input"
                        onChange={onChangeText("firstname")}
                        value={formData.firstname}
                      />
                      {formErrors["firstname"] && (
                        <p className="form-error-text">
                          {formErrors["firstname"]}
                        </p>
                      )}
                    </div>

                    <p className="input-title">LAST NAME:</p>
                    <div className="input-container">
                      <input
                        className="input contact-input"
                        onChange={onChangeText("lastname")}
                        value={formData.lastname}
                      />
                      {formErrors["lastname"] && (
                        <p className="form-error-text">
                          {formErrors["lastname"]}
                        </p>
                      )}
                    </div>
                    <p className="input-title">EMAIL:</p>
                    <div className="input-container">
                      <input
                        className="input contact-input"
                        onChange={onChangeText("email")}
                        value={formData.email}
                      />
                      {formErrors["email"] && (
                        <p className="form-error-text">{formErrors["email"]}</p>
                      )}
                    </div>
                    <p className="input-title">COMPANY:</p>
                    <div className="input-container">
                      <input
                        className="input contact-input"
                        onChange={onChangeText("company")}
                        value={formData.company}
                      />
                      {formErrors["company"] && (
                        <p className="form-error-text">
                          {formErrors["company"]}
                        </p>
                      )}
                    </div>
                    <p className="input-title">INDUSTRY:</p>
                    <div className="input-container">
                      <select
                        className="input contact-input"
                        onChange={onChangeText("industry")}
                        value={formData.industry}
                      >
                        <option></option>
                        <option>Arts</option>
                        <option>Fashion</option>
                        <option>Fragrance</option>
                        <option>Accessories</option>
                        <option>Recreation</option>
                        <option>Hospitality</option>
                        <option>Other</option>
                      </select>
                      {formErrors["industry"] && (
                        <p className="form-error-text">
                          {formErrors["industry"]}
                        </p>
                      )}
                    </div>
                    <div
                      className="btn-wrapper request-br-btn-wrapper"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <div
                        className="continue-btn rb-request-btn"
                        onClick={handleFormSubmitPerState}
                      >
                        <Link>
                          {submitting
                            ? "Requesting..."
                            : "Request For E-Brochure Now"}
                          &nbsp;
                          <svg
                            width="46"
                            height="15"
                            viewBox="0 0 46 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M38.4839 15L37.9089 14.3869L44.85 7.47956L37.9089 0.572207L38.4839 0L46 7.47956L38.4839 15Z"
                              fill="black"
                            />
                            <path
                              d="M45.425 7.0708H0V7.88824H45.425V7.0708Z"
                              fill="black"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <AppDark style={props.style}>
      <Helmet>
        <html lang="en" />
        <title>Request Brochure - BDIC</title>
        <meta name="description" content="Get the BDIC e-brochure" />
      </Helmet>
      <animated.div className="app-container contact-container">
        <Container fluid>
          <form onSubmit={onSubmit}>{renderForm()}</form>
        </Container>
      </animated.div>
      <Footer className="sticky dark" />
    </AppDark>
  );
};

export default RequestBrochure;
