import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { animated } from "react-spring/renderprops";
import "./services.scss";
import Footer from "../commons/footer";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const GoldPackage = () => {
  const [shouldShow1, setShouldShow1] = useState(true);
  const history = useHistory();
  const onRightButtonClick = () => {
    history.push("/services/what-we-do-well/platinum-service");
  };
  const onLeftButtonClick = () => {
    history.push("/what-we-do-well");
  };
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Gold Service - BDIC</title>
        <meta
          name="description"
          content="“Always remember: a brand becomes the most valuable piece of
                    real estate in the world; if it occupies a corner of
                    someone's mind”"
        />
      </Helmet>
      <animated.div className="app-container">
        <div className="page-wrapper">
          <Container fluid>
            <Row>
              <Col md={12} lg={2} className="gold-pkg-headline-text">
                <h1>Gold Service</h1>
              </Col>
              <Col md={12} lg={1} pl-0 pr-0></Col>
              {shouldShow1 ? (
                <Col md={12} lg={4} pull-left>
                  <p className="very-bold">
                    Strategy and Tactics Development -
                  </p>
                  <p className="italics">
                    “Always remember: a brand becomes the most valuable piece of
                    real estate in the world; if it occupies a corner of
                    someone's mind” – John Hegarty.
                  </p>
                  <br />
                  <p>
                    - Positioning and Penetration.
                    <br />
                    - Brand Activation and Product Release (5Rs & 8Ps+).
                    <br />
                    - Visual Communication and Campaign.
                    <br />
                    - Media & PR Planning.
                    <br />
                    - User Experience (UX) Design Planning.
                    <br />
                    - Brand Recall & TopofMind Awareness.
                    <br />- Very High ROI Brand Ambassador Referral.
                  </p>
                </Col>
              ) : (
                <Col md={12} lg={4} pull-left id="2">
                  <p className="very-bold">
                    Product/Productized Development Support -
                  </p>
                  <p className="italics">
                    “There is a science behind the art of storytelling. It is
                    called Semiotics. And nobody gives meaning to a brand and
                    even products, better than us.” – Dolamu Badejo.
                  </p>
                  <br />
                  <p>
                    - Existing Product(s) Evaluation.
                    <br />
                    - Design Concept Development.
                    <br />
                    - Illustration – Concept, Design Art Direction <br />
                    (Products and Packaging).
                    <br />
                    - Range Review, Audit and Planning.
                    <br />
                    - Naming.
                    <br />
                    - Product Placement.
                    <br />
                    - Production Review.
                    <br />
                    - Message, Storytelling, Semiotics Analysis – review and
                    (re)develop/create.
                    <br />
                    - Luxury Commercials (Video, Photoshoot, Audiovisual, et
                    cetera).
                    <br />- Prop Rentals.
                  </p>
                </Col>
              )}

              <Col md={12} lg={1}></Col>
              {shouldShow1 ? (
                <Col md={12} lg={4} pull-left>
                  <p className="very-bold">Brand/Image Consulting -</p>
                  <p className="italics">
                    “It is not enough to be the best at what you do. You must be
                    perceived as the only one who does what you do.”- Jerry
                    Garcia
                  </p>
                  <br />
                  <p>
                    - Luxury Brand Identity and Story Synchronisation.
                    <br />
                    - Brand Expansion.
                    <br />
                    - Business/Product Health Check, Perception Audit and
                    Analysis.
                    <br />
                    - Transitioning (Premium to Luxury).
                    <br />- Customer Experience (CX); Journey Map, Touchpoints:
                    review, audit, build, map, redesign, distribute, implement.
                  </p>
                  <p
                    className="very-bold"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShouldShow1(false)}
                  >
                    MORE{" "}
                    <svg
                      width="31"
                      height="10"
                      viewBox="0 0 31 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.9349 10L25.5474 9.59128L30.225 4.98638L25.5474 0.381471L25.9349 0L31 4.98638L25.9349 10Z"
                        fill="black"
                      />
                      <path
                        d="M30.6125 4.71387H0V5.25883H30.6125V4.71387Z"
                        fill="black"
                      />
                    </svg>
                  </p>
                </Col>
              ) : (
                <Col md={12} lg={4} pull-left id="4">
                  <p className="very-bold">Reputation Management –</p>
                  <p className="italics">
                    “Unfortunately, your reputation often rests not on your
                    ability to do what you say, but rather on your ability to do
                    what people expect.” – Bryant H. McGill.
                  </p>
                  <br />
                  <p>
                    - Brand Experience (BX), User Experience (UX) and Customer
                    Experience (CX).
                    <br />- Private Events & Exhibition Experience (PEEX).
                  </p>
                  <p
                    className="very-bold"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShouldShow1(true)}
                  >
                    <svg
                      width="31"
                      height="10"
                      viewBox="0 0 88 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M14.3786 29L15.4785 27.8147L2.19997 14.4605L15.4785 1.10627L14.3786 0L-3.05176e-05 14.4605L14.3786 29Z"
                          fill="black"
                        />
                        <path
                          d="M1.10001 13.6703H88V15.2507H1.10001V13.6703Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="88"
                            height="29"
                            fill="white"
                            transform="matrix(-1 0 0 1 88 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {"  "}
                    LESS
                  </p>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </animated.div>
      <Footer
        className="sticky"
        showLeftArrow
        showRightArrow
        onLeftButtonClick={onLeftButtonClick}
        onRightButtonClick={onRightButtonClick}
      />
    </>
  );
};

export default GoldPackage;
